<template>
  <v-dialog
    v-model="showRequestPaymentModal"
    persistent
    max-width="600px"
    attach=".call"
  >
    <v-card>
      <HeaderModals
        :titleModal="$t('paymentModals.requestPayment')"
      ></HeaderModals>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="productName"
                dense
                outlined
                hide-details
                :label="
                  !isRaMicro
                    ? $t('paymentModals.product')
                    : $t('paymentModals.product-ra')
                "
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="productPrice"
                dense
                outlined
                type="number"
                min="5"
                :label="
                  !isRaMicro
                    ? $t('paymentModals.price')
                    : $t('paymentModals.price-ra')
                "
                :rules="[numberRule]"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                class="mt-0 checkboxPaymet"
                v-model="taxesCheckbox"
                color="primary"
                dark
                :label="
                  !isRaMicro
                    ? $t('paymentModals.checkbox-easy')
                    : $t('paymentModals.checkbox-ra')
                "
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="closeModal">
          {{ $t("paymentModals.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="requestPaymentFunction"
          :disabled="disableRequestPayment"
          :loading="isRequestPaymentLoading"
        >
          {{ $t("paymentModals.send") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import { getFirebaseToken } from "@/firebase";
import HeaderModals from "./commons/headerModals.vue";
import { isRaMicro } from "../../utils"
export default {
  name: "settingsModal",
  components: { HeaderModals },
  props: ["showRequestPaymentModal", "closeModal", "requestPayment", "isOtherUserAvailable"],
  data: () => ({
    productName: '',
    productPrice: '',
    isRaMicro: isRaMicro(),
    isRequestPaymentLoading: false,
    isRaMicro: isRaMicro(),
    taxesCheckbox: isRaMicro() ? true : false,
    numberRule: v  => {
      if (!v.trim()) return true;
      if (!isNaN(parseFloat(v)) && v >= 10) return true;
      return 'Minimum 10 Euro';
    },
  }),
  methods:{
    async requestPaymentFunction(){
      if ( this.productName !== '' && this.productPrice !== '' && this.productPrice > 0 ){
        this.isRequestPaymentLoading = true;

        let token = await getFirebaseToken()

        let response = await fetch(
          `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/fetchStripeConnectAccount`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (token),
              "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({
              isRaMicro: this.isRaMicro
            })
          })
          let account = await response.json()
          response = await fetch(
          `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/createStripePaymentIntents`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (token),
              "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({
              amount: this.productPrice * 100, // Amount in smallest currency unit (cents)
              currency: 'eur',
              connectId: account.account.id,
              isRaMicro: this.isRaMicro,
            })
          })
          let clientSecret = await response.json()
          this.isRequestPaymentLoading = false;
          if (clientSecret?.client_secret) {
            this.$emit('client-secret-updated', {productName: this.productName, productPrice: this.productPrice, clientSecret: clientSecret.client_secret, taxesCheckbox: this.taxesCheckbox})
          }
      }
    }
  },
  computed:{
    disableRequestPayment(){
      return this.productName == '' || this.productPrice == '' || this.productPrice < 0 || !this.isOtherUserAvailable;
    },
    user(){
      return this.$store.state.user.user
    },
    hasSettings(){
      return this.$store.state.user.user.hasSettings
    }
  }
};

</script>
<style scoped lang="scss">
.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
</style>

<style lang="scss">
.call{
  .v-dialog{
    .theme--light.v-label{
      color: #cccccc;
    }
    .theme--light.v-input input, .theme--light.v-input textarea{
      color: #cccccc !important
    }
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
      color: #cccccc !important
    }
  }


  .checkboxPaymet{
    .theme--dark.v-icon{
      color: #cccccc;
      font-size: 22px;
    }
  }
}
</style>
