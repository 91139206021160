<template>
  <div>
    <v-dialog
      v-model="showVerifyEmailDialog"
      :persistent="true"
      max-width="500"
    >
      <v-card>
        <HeaderModals
          :titleModal="$t('verifyemail.verifyTitle')"
        ></HeaderModals>
        <v-card-text v-if="!isEmailSent" class="text-left mt-2">
          {{ $t("verifyemail.verifyText") }}
        </v-card-text>
        <v-card-text v-if="isEmailSent" class="text-left mt-2">
          {{ $t("verifyemail.emailSent") }}
          <v-icon color="green">mdi-check</v-icon>
        </v-card-text>
        <v-card-actions v-if="!isEmailSent">
          <v-spacer></v-spacer>
          <v-btn @click="closeVerifyEmailDialog" color="primary">{{
            $t("paymentModals.close")
          }}</v-btn>
          <v-btn @click="sendVerificationEmail" color="primary">{{
            $t("generics.resend-email")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Header :openCloseSidebar="openCloseSidebar" v-if="!loading" />
    <SidebarProfile
      :openCloseSidebar="openCloseSidebar"
      :primaryDrawer="primaryDrawer"
      :showSection="showSection"
      v-if="!loading"
    />
    <div
      class="manageProfile"
      :class="{ sidebarOpen: primaryDrawer.model && !isMobile }"
      v-if="!loading"
      :style="
        isRaMicro
          ? 'calc(100% - 100px); margin-top: 100px'
          : 'calc(100% - 64px); margin-top: 64px'
      "
    >
      <HomeProfile :showSection="showSection" v-if="sectionToShow == 'home'" />
      <PersonalInfo :showSection="showSection" v-if="sectionToShow == 'info'" />
      <ManageRooms :showSection="showSection" v-if="sectionToShow == 'rooms'" />
      <Subscriptions
        :showSection="showSection"
        v-if="sectionToShow == 'subscriptions'"
      />
      <Payments :showSection="showSection" v-if="sectionToShow == 'payments'" />
      <AddressBook
        :showSection="showSection"
        v-if="sectionToShow == 'addressbook'"
      />
      <RoomHistory
        :showSection="showSection"
        v-if="sectionToShow == 'roomHistory'"
      />
      <Affiliate
        :showSection="showSection"
        v-if="sectionToShow == 'affilate'"
      />
      <!-- <template v-if="showModalSecurity">
        <Modal2FA
          :showModalSecurity="showModalSecurity"
          :closeModalSecurity="closeModalSecurity"
          :continueToProfile="continueToProfile"
        />
      </template> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Header from "@/components/header.vue";
import SidebarProfile from "@/components/sidebarProfile.vue";
import HomeProfile from "@/components/profile/homeProfile.vue";
import PersonalInfo from "@/components/profile/personalInfo.vue";
import ManageRooms from "@/components/profile/manageRooms.vue";
import Subscriptions from "@/components/profile/subscriptions.vue";
import Payments from "@/components/profile/payments.vue";
import AddressBook from "@/components/profile/addressbook.vue";
import RoomHistory from "@/components/profile/roomhistory.vue";
import Affiliate from "@/components/profile/affiliate.vue";
import HeaderModals from "../components/modals/commons/headerModals.vue";
// import Modal2FA from "../components/modals/modal2fa.vue";
import { isMobile, isRaMicro } from "../utils";
import { sendEmailVerification, getUser } from "@/firebase";

export default {
  name: "manageProfile",
  components: {
    SidebarProfile,
    Header,
    HomeProfile,
    PersonalInfo,
    ManageRooms,
    Subscriptions,
    Payments,
    AddressBook,
    RoomHistory,
    HeaderModals,
    Affiliate
    // Modal2FA
  },
  data: () => ({
    isMobile: isMobile(),
    primaryDrawer: {
      model: isMobile() ? false : true,
      type: "default (no property)",
      clipped: false,
      floating: false
    },
    sectionToShow: "home",
    windowWidth: window.outerWidth,
    showVerifyEmailDialog: false,
    isEmailSent: false,
    isRaMicro: isRaMicro()
    // showModalSecurity: false
  }),
  watch: {
    loading: {
      inmediate: true,
      handler: function(value) {
        if (!value) {
          if (!this.user) {
            this.$router.replace("/");
          } else {
            if (this.user.security) {
              this.showModalSecurity = true;
            }
          }
        }
      }
    }
  },
  created() {
    window.onresize = () => {
      if (!this.isMobile) {
        if (window.outerWidth < 1180 && window.outerWidth < this.windowWidth) {
          this.primaryDrawer.model = false;
        } else if (
          window.outerWidth > 1180 &&
          window.outerWidth > this.windowWidth
        ) {
          this.primaryDrawer.model = true;
        }
        this.windowWidth = window.outerWidth;
      }
    };
    if (sessionStorage.getItem("returnPage") === "subscriptions") {
      this.sectionToShow = "subscriptions";
      sessionStorage.removeItem("returnPage");
    }
  },
  mounted() {
    if (this.user && !getUser().emailVerified) {
      setTimeout(() => {
        this.$store.dispatch("USER_FETCH", {
          user: this.user
        });
        setTimeout(() => {
          if (this.user && !getUser().emailVerified) {
            this.showVerifyEmailDialog = true;
          }
        }, 2500);
      }, 2500);
      // this.sendVerificationEmail();
    }
    // if (this.user && this.user.security) {
    //   this.showModalSecurity = true;
    // }
  },
  methods: {
    // continueToProfile(value) {
    //   if (value) {
    //     this.closeModalSecurity(value);
    //   }
    // },
    // closeModalSecurity(value) {
    //   if (value) {
    //     this.showModalSecurity = false;
    //   } else {
    //     this.$router.replace("/");
    //   }
    // },
    showSection(section) {
      if (this.sectionToShow !== section) {
        this.sectionToShow = section;
      } else {
        this.sectionToShow = "home";
      }
    },
    openCloseSidebar() {
      this.primaryDrawer.model = !this.primaryDrawer.model;
    },
    sendVerificationEmail() {
      if (this.user) {
        let lang = navigator.language || navigator.userLanguage;
        sendEmailVerification({ languageCode: lang.slice(0, 2) });
        this.isEmailSent = true;
        setTimeout(() => {
          this.closeVerifyEmailDialog();
        }, 2500);
      }
    },
    closeVerifyEmailDialog() {
      this.$router.replace("/");
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    loading() {
      return this.$store.state.user.loading;
    }
  }
};
</script>
<style scoped lang="scss">
.manageProfile {
  background-color: #fff;
  word-break: break-word;
  height: calc(100% - 64px);
  margin: 0;
  padding: 0;
  margin-top: 64px;
  transition: all 0.2s linear;
  &.sidebarOpen {
    width: calc(100% - 320px);
    margin-left: 320px;
  }
}
</style>
