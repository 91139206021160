<template>
  <v-footer class="pa-12 pb-0 mx-0 mt-3" dark color="#1976d2" padless>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <div class="text-justify">
            <p>
              <strong>{{ $t("footer.col-1-title") }}</strong>
            </p>
            <p class="caption" v-if="isRaMicro">
              vOffice SE
              <br />Am Borsigturm 56 <br />13507 Berlin <br />Deutschland
            </p>
            <p class="caption" v-else>
              Maram Strategy Consulting S.L. <br />Carretera San Jeronimo 15 /
              Piso 2 <br />{{ $t("footer.col-1-adress") }}<br />{{
                $t("footer.col-1-land")
              }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div class="text-justify">
            <p>
              <strong>{{ $t("footer.col-2-title") }}</strong>
            </p>
            <p class="caption">
              {{ $t("footer.col-2-text-2") }}<br />

              <a
                href=""
                style="color:white;"
                class="text-decoration-underline"
                target=""
                v-if="!isRaMicro"
                >support@easymeet.me</a
              >
              <a
                href=""
                style="color:white;"
                class="text-decoration-underline"
                target=""
                >info@voffice-se.com</a
              >
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div class="text-justify">
            <p>
              <strong>{{ $t("footer.col-3-title") }}</strong>
            </p>
            <p class="caption text-decoration-underline">
              Twitter <br />Facebook
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <div class="text-justify">
            <p>
              <strong>{{ $t("footer.col-4-title") }}</strong>
            </p>
            <p class="caption">
              <a
                href="/about"
                style="color:white;"
                target="_self"
                v-html="$t('footer.col-4-link-1')"
              >
              </a>
              <br v-if="!isRaMicro" />
              <a
                v-if="!isRaMicro"
                href="/plans"
                style="color:white;"
                target="_self"
              >
                {{ $t("footer.col-4-link-2") }}
              </a>
              <br />
              <a href="/help" style="color:white;" target="_self">{{
                $t("links.help")
              }}</a>
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-row
      style="width:100%"
      class="padXS backColorFoot mt-3 pt-2 mb-0 pb-0 mx-n12"
    >
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <p class="copy caption text-justify mb-0">
              Copyright © {{ new Date().getFullYear() }} vOffice SE
              {{ $t("footer.copyright") }}
            </p>
          </v-col>
          <v-col cols="12" sm="6" class="text-justify privacyMenu">
            <p class="caption">
              <!-- <a
                v-if="!isRaMicro"
                class="text-decoration-underline"
                href="/imprint"
                target="_self"
                >{{ $t("links.imprint") }}</a
              >
              <a
                v-if="isRaMicro"
                class="text-decoration-underline"
                href="https://www.ra-micro-gmbh.de/about"
                target="_blank"
                >{{ $t("links.imprint") }}</a
              > -->
              <router-link class="text-decoration-underline" to="/imprint">{{
                $t("links.imprint")
              }}</router-link>
              &#32;&#32;&#32;&#124;&#32;&#32;&#32;
              <router-link class="text-decoration-underline" to="/terms">{{
                $t("links.terms")
              }}</router-link>

              &#32;&#32;&#32;&#124;&#32;&#32;&#32;
              <router-link class="text-decoration-underline" to="/privacy">{{
                $t("links.privacy")
              }}</router-link>

              <template v-if="!isRaMicro"
                >&#32;&#32;&#32;&#124;&#32;&#32;&#32;</template
              >
              <router-link
                v-if="!isRaMicro"
                class="text-decoration-underline"
                to="/cookies"
                >{{ $t("links.cookies") }}</router-link
              >
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-row>
  </v-footer>
</template>
<script>
import { isRaMicro } from "../utils";
export default {
  name: "Footer",
  data: () => ({
    isRaMicro: isRaMicro()
  }),
  created() {},
  methods: {}
};
</script>

<style scoped lang="scss">
* {
  font-family: Muli, sans-serif !important;
}
@media only screen and (min-width: 700px) {
  .privacyMenu {
    text-align: end !important;
  }
}
@media only screen and (max-width: 700px) {
  .privacyMenu {
    padding-top: 0px;
  }
  .copy {
    text-align: left !important;
  }
  .padXS {
    padding-right: 40px !important;
    padding-left: 40px !important;
  }
}
.backColorFoot {
  background-color: #00000025;
  color: #ffffffad;
}
.privacyMenu a {
  color: #ffffffad;
}
</style>
