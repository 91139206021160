<template>
  <v-navigation-drawer
    :style="isRaMicro ? 'top: 100px' : 'top: 64px'"
    :height="isRaMicro ? 'calc(100% - 100px)' : 'calc(100% - 64px)'"
    v-model="primaryDrawer.model"
    fixed
    :dark="!isRaMicro"
    :color="isRaMicro ? '#fff' : '#1976d2'"
    :temporary="false"
    width="320"
  >
    <v-list>
      <v-list-item
        class="text-left"
        @click="
          showSection('home');
          handlerClickBars();
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            <font-awesome-icon
              :icon="['fal', 'home']"
              :style="{ fontSize: 18 }"
              class="mr-4 w20"
            />
            {{ $t("links.home") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="text-left"
        @click="
          showSection('addressbook');
          handlerClickBars();
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            <font-awesome-icon
              :icon="['fal', 'address-book']"
              :style="{ fontSize: 18 }"
              class="mr-4 w20"
            />
            {{ $t("profile.addressBook.addressbook") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="text-left"
        @click="
          showSection('info');
          handlerClickBars();
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            <font-awesome-icon
              :icon="['fal', 'address-card']"
              :style="{ fontSize: 18 }"
              class="mr-4 w20"
            />
            {{ $t("sidebar.personal-info") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="text-left"
        @click="
          showSection('rooms');
          handlerClickBars();
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            <font-awesome-icon
              :icon="['fal', 'sitemap']"
              :style="{ fontSize: 18 }"
              class="mr-4 w20"
            />
            {{ $t("sidebar.manage-rooms") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="!isRaMicro"
        class="text-left"
        @click="
          showSection('subscriptions');
          handlerClickBars();
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            <font-awesome-icon
              :icon="['fal', 'money-check-edit-alt']"
              :style="{ fontSize: 18 }"
              class="mr-4 w20"
            />
            {{ $t("sidebar.subscriptions") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="text-left"
        @click="
          showSection('payments');
          handlerClickBars();
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            <font-awesome-icon
              :icon="['fal', 'hands-usd']"
              :style="{ fontSize: 18 }"
              class="mr-4 w20"
            />
            {{ $t("sidebar.client-payments") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        class="text-left"
        @click="
          showSection('roomHistory');
          handlerClickBars();
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            <font-awesome-icon
              :icon="['fal', 'history']"
              :style="{ fontSize: 18 }"
              class="mr-4 w20"
            />
            {{ $t("profile.roomHistory.titel") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="!isRaMicro"
        class="text-left"
        @click="
          showSection('affilate');
          handlerClickBars();
        "
      >
        <v-list-item-content>
          <v-list-item-title>
            <font-awesome-icon
              :icon="['fal', 'stars']"
              :style="{ fontSize: 18 }"
              class="mr-4 w20"
            />
            {{ $t("sidebar.affiliate") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="text-left" @click="$router.replace('/')">
        <v-list-item-content>
          <v-list-item-title>
            <font-awesome-icon
              :icon="['fal', 'arrow-left']"
              :style="{ fontSize: 18 }"
              class="mr-4 w20"
            />
            {{ $t("links.home") }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import { isMobile, isRaMicro } from "../utils";
import { getFirebaseClaims } from "@/firebase";
export default {
  name: "App",
  props: ["primaryDrawer", "showSection", "openCloseSidebar"],
  components: {},
  data: () => ({
    isMobile: isMobile(),
    isStarter: false,
    isRaMicro: isRaMicro()
  }),
  created() {
    getFirebaseClaims(true)
      .then(claims => {
        if (!claims.hasSub) {
          this.isStarter = true;
        }
      })
      .catch(err => {
        console.warn("getFirebaseClaims Err:", err);
      });
  },
  methods: {
    handlerClickBars() {
      if (this.isMobile) {
        if (this.openCloseSidebar) {
          this.openCloseSidebar();
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>

<style lang="scss">
.w20 {
  width: 20px !important;
}
</style>
