<template>
  <v-card>
    <div class="d-flex align-center height-100">
      <v-row>
        <v-col class="text-center" cols="12">
          <v-img
            class="logo mb-2"
            contain
            src="../assets/easymeet_logo-b.svg"
          ></v-img>
        </v-col>
        <v-col class="text-center" cols="12">
          <v-text-field
            class="textInput"
            v-on:keyup.enter="enterRoom"
            dense
            outlined
            :label="$t('generics.displayName')"
            required
            v-model="displayName"
          ></v-text-field>
          <v-btn
            color="primary"
            depressed
            @click="enterRoom"
            :disabled="isRoomLocked || !displayName"
          >
            {{ $t("landingPage.enterRoom") }}
          </v-btn>
        </v-col>
        <v-col class="text-center" cols="12">
          <span v-if="isRoomLocked && isLoading" class="text-caption">
            <v-progress-circular
              indeterminate
              color="primary"
              class="progressLanding"
            ></v-progress-circular>
            {{ $t("landingPage.loading") }}
          </span>
          <span
            class="text-caption"
            v-if="isRoomLocked && !isTimedRoom && !isLoading"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              class="progressLanding"
            ></v-progress-circular>
            {{ $t("landingPage.waitingApproval") }}
          </span>
          <span
            class="text-caption"
            v-if="isRoomLocked && timedRoomError && !isLoading"
          >
            {{ $t("landingPage.notAvailable") }} <br />
            {{ timedRoomError }}
          </span>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
/* eslint-disable */
import { fetchRoomStatus, getFirebaseToken, getPushNotificationDevice } from "@/firebase";
import { isRaMicro } from "../utils.js";
  export default {
    name: "LandingPage",
    props: [],
    components: {
    },
    data: () => ({
      displayName: "",
      isRoomLocked: true,
      timedRoomError: "",
      isLoading: true,
      isRaMicro: isRaMicro(),
      baseUrl: window.location.origin,
    }),
    created(){},
    mounted(){
      this.checkRoomLockStatus()
      if (sessionStorage.getItem("displayName")) {
        this.displayName = sessionStorage.getItem("displayName");
      }
      else if (this.$store.state.user.user) {
        this.displayName = this.$store.state.user.user.displayName || "";
      }
      else {
        //There are some delays in populating $store if the user enters the url directly
        setTimeout(() => {
          //this.notifyRoomOwner(false)
          if (this.$store.state.user.user) {
            this.displayName = this.$store.state.user.user.displayName || "";
          }
        }, 2500);
      }
    },
    methods: {
      async checkRoomLockStatus() {
        let roomStatus = await fetchRoomStatus(this.$route.params.mainRoom, this.$route.params.subRoom);
        this.isLoading = false
        if (roomStatus.isTimedRoom && roomStatus.beginTs < Date.now() && roomStatus.endTs > Date.now()) {
          this.isRoomLocked = true;
          this.timedRoomError = Date(roomStatus.beginTs).slice(0,21) + ' - ' + Date(roomStatus.endTs).slice(0,21)
          return;
        }
        if (!roomStatus.locked) {
          this.isRoomLocked = false;
          return;
        }
        // Poll database every 10s for room status
        let pollId = setInterval(() => {
          fetchRoomStatus(this.$route.params.mainRoom, this.$route.params.subRoom)
          .then((roomStatus) => {
            if (!roomStatus.locked) {
              this.isRoomLocked = false
              clearInterval(pollId);
            }
          })
        }, 10000);
        // Stops polling after 5min
        setTimeout(() => {
          clearInterval(pollId);
        }, 300000);
      },
      async enterRoom() {
        let route = "/c/"
        if (this.$route.name === "room_et") {
          route = "/et/"
        } else if (this.$route.name === "room_ad") {
          route = "/ad/"
        } else if (this.$route.name === "room_sc") {
          route = "/sc/"
        }

        let room = this.$route.params.mainRoom;
        if (this.displayName) {
          sessionStorage.setItem("displayName", this.displayName);
        }
        if (this.$route.params.subRoom) {
          room = room + "/" + this.$route.params.subRoom;
        }
        if (this.$route.query.f && this.$route.query.ts ) {
          room += '?f=' + this.$route.query.f + '&ts=' + this.$route.query.ts;
          if (this.$route.query.token) {
            room += '&token=' + this.$route.query.token;
          }
          if (this.$route.query.e) {
            room += '&e=' + this.$route.query.e;
          }
        }
        this.$router.replace("/c/" + room);
      }
    },
    computed: {
      user() {
        return this.$store.state.user.user;
      },
    },
  };
</script>
<style scoped lang="scss">
.logo{
  max-width: 350px;
  margin:0 auto;
}
.height-100 {
  height:100vh;
}
.textInput {
  max-width:350px;
  margin: 0 auto;
}

.progressLanding{
    height: 15px !important;
    width: 15px !important;
    margin-left: 5px !important;
}
</style>