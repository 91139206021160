<template>
  <v-container class="containerStartPage">
    <v-card elevation="0">
      <div class="d-flex align-center height-100">
        <v-row>
          <v-col class="text-center" cols="12">
            <p
              v-html="
                $t('ra-version.start-page.text', {
                  0: `<a href='https://stripe.com' target='_blank'>Stripe</a>`
                })
              "
            ></p>
          </v-col>
          <v-col class="text-center" cols="12">
            <v-img
              class="logo mb-2"
              contain
              src="../assets/easymeet_logo-b.svg"
            ></v-img>
          </v-col>

          <v-col class="text-center pb-0" cols="12">
            <v-btn
              depressed
              color="primary"
              @click="startNow"
              :disabled="fetchingStripe"
            >
              <font-awesome-icon
                class="mr-3"
                :icon="['fas', 'phone-alt']"
                :style="{ fontSize: 15 }"
              />
              {{ $t("startnow") }}
            </v-btn>
          </v-col>
          <v-col class="text-center pb-0" cols="12">
            <v-checkbox
              class="dontShowAgain"
              v-model="user.dontShowStartPage"
              :label="$t('ra-version.start-page.dontShowAgain')"
              @change="markDontShowAgain"
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <NeedsLogin
      :showNeedsLogin="showNeedsLogin"
      :closeModal="closeNeedsLogin"
      :goToLogin="goToLogin"
      :goToRegister="goToRegister"
    />
    <StripeConnectModal
      :showStripeConnect="showStripeConnect"
      :closeModal="closeStripeConnect"
      :isStart="true"
    />
  </v-container>
</template>
<script>
/* eslint-disable */
import { getFirebaseToken } from "@/firebase";
import { randomString, availableLanguages, isRaMicro } from "../utils.js";
import NeedsLogin from "./modals/needsLogin.vue";
import StripeConnectModal from "./modals/setupStripeConnect.vue";
import FingerprintJS from "fingerprintjs";

  export default {
    components: { NeedsLogin, StripeConnectModal }, //Footer
    name: "Start",
    props: [],
    data: () => ({
    userHasPaymentsActive: false,
    showCallComponent: false,
    toggle_exclusive: 2,
    loading: false,
    isRaMicro: isRaMicro(),
    showNeedsLogin: false,
    showStripeConnect: false,
    showAgain: false,
    fetchingStripe: false
  }),
  watch: {
    loading: {
      immediate: true,
      handler: function (value) {
        if (!value) {
          this.getUserHasPaymentSet();
        }
      } 
    },
  },
  mounted() {
    if (this.user) {
      this.getUserHasPaymentSet();
    }
  },
  methods: {
    startNow(){
      if ( this.user ){
        if ( this.userHasPaymentsActive ){
          this.initCall();
        }else{
          this.showStripeConnect = true;
        }
      }else{
        this.showNeedsLogin = true;
      }
    },
    closeStripeConnect(){
      this.showStripeConnect = false;
    },
    closeNeedsLogin(){
      this.showNeedsLogin = false;
    },
    async initCall(){
      const roomId = randomString(9);
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/reserveRandomRoom`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          room: roomId
        })
      })
      .then(response => response.json())
      .then(response => { 
        if (response.success) {
          let fingerPrint = new FingerprintJS().get();
          let ts = Date.now();
          this.$router.push({ path: `/c/${roomId}?f=${fingerPrint}&ts=${ts}` });
          this.showCallComponent = true;
        }
        else {
          // Try again with a different room
          this.initCall()
        }
      })
    },
    goToLogin(){
      this.$router.push({ path: `/login` });
    },
    goToRegister(){
      this.$router.push({ path: `/register` });
    },
    async showLinkModal(){
      if ( this.user && !this.hasNotPayments ){
        this.loading = true;
        let subRoom = await this.generateRandomSubroom();
        let room = this.user.mainRoom.name + '/' + subRoom; 
        this.loading = false;
        let fingerPrint = new FingerprintJS().get();
        let ts = Date.now();
        this.$router.push({ path: `/c/${room}?f=${fingerPrint}&ts=${ts}`, query: { payLink: 'true' }});
      }
    },
    async generateRandomSubroom() {
      if (await this.ensureMainRoom()) {
        let savedRooms = [];
        let subRoomName = randomString(9); 

        if (this.$store.state.user.user.savedRooms) {
          savedRooms = this.$store.state.user.user.savedRooms.slice();
        }
        savedRooms.push({ name: subRoomName, online: true, locked: true, isPayLinkRoom: true });
        let response = await fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveSubrooms`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: this.user.mainRoom.name,
            online: this.user.mainRoom.online,
            subroom: savedRooms
          })
        })

        let responseJson = await response.json()
        if (responseJson.success) {
            this.$store.dispatch("SAVE_USER_INFO", {
              user: this.$store.state.user,
              userInfo: {
                savedRooms: savedRooms
              }
            });
            return subRoomName;
        }
      }
    },
    async ensureMainRoom() {
      if (this.user.mainRoom.name) {
        return true
      } else {
        // If user does not have a main room, generate a random one
        let mainRoomName = randomString(9)
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/claimRoom`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: mainRoomName
          })
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              this.$store
                .dispatch("SAVE_USER_INFO", {
                  user: this.$store.state.user,
                  userInfo: {
                    mainRoom: { name: mainRoomName, online: true }
                  }
                })
              return true
            } else {
              return false
            }
          })
          .catch(error => {
            console.warn("Error on claimRoom", error);
            return false
          });
      }
    },
    async getUserHasPaymentSet(){
      this.fetchingStripe = true;
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/fetchStripeConnectAccount`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            isRaMicro: this.isRaMicro,
          })
        }
      )
        .then(response => response.json())
        .then(response => {
          this.fetchingStripe = false;
          if ( response.success && response.account.charges_enabled ) { // 
            this.userHasPaymentsActive = true;
          } else {
           this.userHasPaymentsActive = false;
          }
        })
        .catch(error => {
          this.fetchingStripe = false;
          console.log(error);
        });
    },
    markDontShowAgain(value){
      this.$store.dispatch("SAVE_USER_INFO", {
        user: this.$store.state.user,
        userInfo: {
         dontShowStartPage: this.user.dontShowStartPage
        }
      });
     
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    loading() {
      return this.$store.state.user.loading
    },
    hasSettings(){
      return this.$store.state.user.user.hasSettings
    },
    getTooltipButton(){
      if ( this.user && this.hasNotPayments ){
        return this.$t('startPage.ttEuroBtnActivate');
      }else if ( this.user && !this.hasNotPayments ){
        return this.$t('startPage.ttEuroBtnGetPaid');
      }else if ( !this.user ){
        return this.$t('startPage.ttEuroBtnLogin');
      }
    }
  }
};

</script>
<style scoped lang="scss">
.logo{
  max-width: 350px;
  margin:0 auto;
  max-height: 66px;
}
.height-100 {
  height:100vh;
}
.textInput {
  max-width:350px;
  margin: 0 auto;
}

</style>

<style lang="scss">
.dontShowAgain{
  .v-input__slot{
    width: auto;
    margin: 0 auto;
  }
}
</style>