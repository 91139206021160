<template>
  <v-card class="cardProfile" flat>
    <span
      v-if="isMobile"
      class="text-right closeButtonPosition links"
      @click="showSection('home')"
      >x</span
    >
    <v-row class="rowPills">
      <v-col cols="12" class="pb-5">
        <h1 class="pb-2">
          {{ $t("sidebar.manage-rooms") }}
        </h1>
        <p>
          <span v-html="$t('profile.manageRooms.subtitle')"></span><br />
          <span
            v-if="isStarter && !isLoading && !isRaMicro"
            v-html="
              $t('profile.manageRooms.subtitleStarter', [$t('plans.pro.title')])
            "
          >
          </span>
        </p>
      </v-col>
      <v-col
        cols="12"
        class="bordered text-left"
        :class="{ 'pa-6 mb-6': !isMobile }"
        v-if="!isStarter || isRaMicro"
      >
        <div v-if="mainRoom">
          <h2 class="pb-1 title">
            {{ $t("profile.manageRooms.mainRoom") }}
          </h2>
          <v-data-table
            :headers="tableHeaderRoom"
            hide-default-header
            hide-default-footer
            :items="[
              { room: mainRoom, online: mainRoomOnline, actions: 'actions' }
            ]"
          >
            <template slot="no-data">
              {{ $t("profile.manageRooms.noSavedRooms") }}
            </template>
            <template #item.room="{ item }">
              <div class="text-left">
                <a
                  target="_blank"
                  @click="openRoom(item.room + fingerPrintString())"
                >
                  {{ baseUrl + "/r/" + item.room }}
                </a>
                <a
                  target="_blank"
                  @click="
                    copyToClipboard(
                      baseUrl + '/r/' + item.room + fingerPrintString()
                    )
                  "
                  class="pl-0"
                >
                  <font-awesome-icon :icon="['fal', 'copy']" />
                </a>
              </div>
            </template>
            <template #item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <a
                    target="_blank"
                    @click="toggleMainroomOnline(item.room)"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'user-friends']"
                      :style="{
                        fontSize: 15,
                        color: item.online ? 'green' : 'red'
                      }"
                    />
                  </a>
                </template>
                <span>{{
                  !item.online
                    ? $t("profile.manageRooms.notificationTooltip")
                    : $t("profile.manageRooms.disabledNotificationTooltip")
                }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <a
                    target="_blank"
                    @click="deleteRoom(item.room)"
                    class="ml-4"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <font-awesome-icon
                      :icon="['fal', 'trash']"
                      :style="{ fontSize: 15, color: 'red' }"
                    />
                  </a>
                </template>
                <span>{{ $t("profile.manageRooms.deleteRoomTooltip") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <h2 class="pb-1 title">
            {{ $t("profile.manageRooms.savedRooms") }}
          </h2>
          <p class="body-1">
            <v-data-table
              :headers="tableHeaderRoom"
              hide-default-header
              :items="roomList"
              :footer-props="{
                'items-per-page-text': footerText,
                'items-per-page-all-text': allText
              }"
              :header-props="{
                'sort-by-text': sortByText
              }"
            >
              <template slot="no-data">
                {{ $t("profile.manageRooms.noSavedRooms") }}
              </template>
              <template #item.room="{ item }">
                <div class="text-left">
                  <a
                    target="_blank"
                    @click="
                      openRoom(mainRoom + '/' + item.room + fingerPrintString())
                    "
                  >
                    {{ baseUrl + "/r/" + mainRoom + "/" + item.room }}
                  </a>
                  <a
                    target="_blank"
                    @click="
                      copyToClipboard(
                        baseUrl +
                          '/r/' +
                          mainRoom +
                          '/' +
                          item.room +
                          fingerPrintString()
                      )
                    "
                    class="pl-0"
                  >
                    <font-awesome-icon :icon="['fal', 'copy']" />
                  </a>
                </div>
              </template>
              <template #item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      target="_blank"
                      @click="toggleSubroomOnline(item.room)"
                      v-on="on"
                      v-bind="attrs"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'user-friends']"
                        :style="{
                          fontSize: 15,
                          color: item.online ? 'green' : 'red'
                        }"
                      />
                    </a>
                  </template>
                  <span>{{
                    $t("profile.manageRooms.notificationTooltip")
                  }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <a
                      target="_blank"
                      @click="deleteSubRoom(item.room)"
                      class="ml-4"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <font-awesome-icon
                        :icon="['fal', 'trash']"
                        :style="{ fontSize: 15, color: 'red' }"
                      />
                    </a>
                  </template>
                  <span>{{ $t("profile.manageRooms.deleteRoomTooltip") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </p>
          <h2 class="pb-1 pt-3 title">
            {{ $t("profile.manageRooms.addRoom") }}
          </h2>
          <div class="d-flex mt-2 text-left">
            <v-text-field
              v-on:keyup.enter="saveSubRoom"
              dense
              outlined
              :label="$t('profile.manageRooms.roomName')"
              :hint="$t('profile.manageRooms.inputHints')"
              required
              :value="subRoomInput"
              :rules="[rules.roomName]"
              v-mutate="enableSaveButton"
              @input="_ => (subRoomInput = _)"
            ></v-text-field>
            <v-btn
              :disabled="isSaveSubroomButtonDisabled"
              color="primary"
              depressed
              class="ml-5 mt-0"
              @click="saveSubRoom"
            >
              {{ $t("paymentModals.save") }}
            </v-btn>
          </div>
        </div>
        <div v-if="!mainRoom">
          <h2 class="pb-1 title">
            {{ $t("profile.manageRooms.mainRoom") }}
          </h2>
          <div class="d-flex mt-2 text-left">
            <v-text-field
              v-on:keyup.enter="saveMainRoom"
              dense
              outlined
              :label="$t('profile.manageRooms.roomName')"
              :hint="$t('profile.manageRooms.inputHints')"
              required
              :value="mainRoomInput"
              :rules="[rules.roomName]"
              v-mutate="enableSaveButton"
              @input="_ => (mainRoomInput = _)"
            ></v-text-field>
            <v-btn
              :disabled="isSaveMainroomButtonDisabled"
              color="primary"
              depressed
              class="ml-5 mt-0"
              @click="saveMainRoom"
            >
              {{ $t("paymentModals.save") }}
            </v-btn>
          </div>
        </div>
        <v-alert
          :value="showSuccessMessage"
          type="success"
          class="genericAlerts"
        >
          {{ successMessage }}
        </v-alert>
        <v-alert :value="showErrorMessage" type="error" class="genericAlerts">
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import Vue from "vue";
import {
  getFirebaseClaims,
  getFirebaseToken,
  getDeviceToken
} from "../../firebase";
import { isRaMicro, isMobile } from "../../utils";
export default {
  name: "ManageRooms",
  props: ["showSection"],
  components: {},
  data: () => ({
    isLoading: true,
    isStarter: true,
    baseUrl: window.location.origin,
    isSaveSubroomButtonDisabled: true,
    isSaveMainroomButtonDisabled: true,
    showErrorMessage: false,
    showSuccessMessage: false,
    errorMessage: null,
    successMessage: null,
    tableHeaderRoom: [
      {
        text: "",
        align: "start",
        sortable: true,
        value: "room"
      },
      {
        text: "",
        align: "right",
        sortable: false,
        value: "actions"
      }
    ],
    rules: {
      roomName: value => {
        if (!value || value.length == 0) {
          return true;
        }
        const pattern = /^[a-z0-9 _-]+$/;
        return (
          pattern.test(value) ||
          Vue.prototype.$t("profile.manageRooms.inputError")
        );
      }
    },
    mainRoom: null,
    mainRoomOnline: false,
    mainRoomInput: null,
    roomList: [],
    subRoomInput: null,
    token: null,
    isRaMicro: isRaMicro(),
    isMobile: isMobile()
  }),
  created() {
    getFirebaseClaims(true)
      .then(claims => {
        if (claims.hasSub) {
          this.isStarter = false;
        }
        this.isLoading = false;
      })
      .catch(err => {
        console.warn("getFirebaseClaims Err:", err);
      });
  },
  mounted() {
    if (this.$store.state.user.user.mainRoom) {
      this.mainRoom = this.$store.state.user.user.mainRoom.name || "";
      this.mainRoomOnline =
        this.$store.state.user.user.mainRoom.online || false;
    }

    //temporary fix: clean up old db entries from before this commit
    if (!this.mainRoom && this.$store.state.user.user.mainRoom) {
      this.deleteRoom(this.$store.state.user.user.mainRoom);
    }
    ////

    getDeviceToken().then(token => {
      this.token = token;
    });

    this.loadTable();
  },
  methods: {
    enableSaveButton() {
      const pattern = /^[a-z0-9 _-]+$/;
      if (pattern.test(this.subRoomInput)) {
        this.isSaveSubroomButtonDisabled = false;
      } else {
        this.isSaveSubroomButtonDisabled = true;
      }
      if (pattern.test(this.mainRoomInput)) {
        this.isSaveMainroomButtonDisabled = false;
      } else {
        this.isSaveMainroomButtonDisabled = true;
      }
    },
    async saveMainRoom() {
      if (this.isSaveMainroomButtonDisabled) {
        return;
      }
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/claimRoom`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          room_name: this.mainRoomInput
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            this.$store
              .dispatch("SAVE_USER_INFO", {
                user: this.$store.state.user,
                userInfo: {
                  mainRoom: { name: this.mainRoomInput, online: true }
                }
              })
              .then(() => {
                this.successMessage = this.$t("profile.manageRooms.roomSaved");
                this.showSuccessMessage = true;
                setTimeout(() => {
                  this.showSuccessMessage = false;
                }, 2500);
                this.isSaveMainroomButtonDisabled = true;
                this.mainRoom = this.mainRoomInput;
                this.mainRoomOnline = true;
                this.mainRoomInput = "";
                this.loadTable();
              });
          } else {
            if (response.isOwner) {
              this.errorMessage = this.$t("profile.manageRooms.roomExist");
            } else {
              this.errorMessage = this.$t(
                "profile.manageRooms.roomReservedByOther"
              );
            }
            this.showErrorMessage = true;
            setTimeout(() => {
              this.showErrorMessage = false;
            }, 2500);
            return;
          }
        })
        .catch(error => {
          this.errorMessage = this.$t("profile.manageRooms.savingRoomError");
          this.showErrorMessage = true;
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 2500);
          console.warn("Error on claimRoom", error);
        });
    },
    async saveSubRoom() {
      if (this.isSaveSubroomButtonDisabled) {
        return;
      }
      let savedRooms = [];
      if (this.$store.state.user.user.savedRooms) {
        savedRooms = this.$store.state.user.user.savedRooms.slice();
      }

      if (
        savedRooms.findIndex(room => room.name === this.subRoomInput) === -1
      ) {
        savedRooms.push({ name: this.subRoomInput, online: true });
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveSubrooms`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: this.mainRoom,
            online: this.mainRoomOnline,
            subroom: savedRooms
          })
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              return this.$store.dispatch("SAVE_USER_INFO", {
                user: this.$store.state.user,
                userInfo: {
                  savedRooms: savedRooms
                }
              });
            }
          })
          .then(() => {
            this.successMessage = this.$t("profile.manageRooms.roomSaved");
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.showSuccessMessage = false;
            }, 2500);
            this.isSaveSubroomButtonDisabled = true;
            this.subRoomInput = "";
            this.$store.state.user.user.savedRooms = savedRooms;
            this.loadTable();
          })
          .catch(error => {
            this.errorMessage = this.$t("profile.manageRooms.savingRoomError");
            this.showErrorMessage = true;
            setTimeout(() => {
              this.showErrorMessage = false;
            }, 2500);
            console.warn("Error on saveSubrooms", error);
          });
      } else {
        this.errorMessage = this.$t("profile.manageRooms.roomExist");
        this.showErrorMessage = true;
        setTimeout(() => {
          this.showErrorMessage = false;
        }, 2500);
      }
    },
    copyToClipboard(roomId) {
      navigator.clipboard.writeText(roomId);
      this.successMessage = this.$t("profile.manageRooms.copiedToClipboard");
      this.showSuccessMessage = true;
      setTimeout(() => {
        this.showSuccessMessage = false;
      }, 2500);
    },
    async toggleMainroomOnline() {
      this.mainRoomOnline = !this.mainRoomOnline;
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveSubrooms`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          room_name: this.mainRoom,
          online: this.mainRoomOnline,
          subroom: this.$store.state.user.user.savedRooms
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            this.$store.dispatch("SAVE_USER_INFO", {
              user: this.$store.state.user,
              userInfo: {
                mainRoom: { name: this.mainRoom, online: this.mainRoomOnline }
              }
            });
          }
        })
        .catch(error => {
          this.errorMessage = this.$t("profile.manageRooms.savingRoomError");
          this.showErrorMessage = true;
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 2500);
          console.warn("Error on saveSubrooms", error);
        });
    },
    async toggleSubroomOnline(roomId) {
      let savedRooms = this.$store.state.user.user.savedRooms.slice() || [];
      let index = savedRooms.findIndex(room => {
        if (room.name === roomId) {
          return true;
        }
      });
      if (index != -1) {
        this.roomList[index].online = !savedRooms[index].online; //fake ui responsiveness since this process can take a 1-2s to complete
        savedRooms[index].online = !savedRooms[index].online;
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveSubrooms`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: this.mainRoom,
            online: this.mainRoomOnline,
            subroom: savedRooms
          })
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              this.$store.dispatch("SAVE_USER_INFO", {
                user: this.$store.state.user,
                userInfo: {
                  savedRooms: savedRooms
                }
              });
              this.$store.state.user.user.savedRooms = savedRooms;
              this.loadTable();
            }
          })
          .catch(error => {
            this.errorMessage = this.$t("profile.manageRooms.savingRoomError");
            this.showErrorMessage = true;
            setTimeout(() => {
              this.showErrorMessage = false;
            }, 2500);
            console.warn("Error on claimRoom", error);
          });
      }
    },
    async deleteSubRoom(roomId) {
      let savedRooms = this.$store.state.user.user.savedRooms || [];
      let index = savedRooms.findIndex(room => {
        if (room.name === roomId) {
          return true;
        }
      });
      if (index != -1) {
        savedRooms.splice(index, 1);
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveSubrooms`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: this.mainRoom,
            online: this.mainRoomOnline,
            subroom: savedRooms
          })
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              this.$store.dispatch("SAVE_USER_INFO", {
                user: this.$store.state.user,
                userInfo: {
                  savedRooms: savedRooms
                }
              });
              this.successMessage = this.$t("profile.manageRooms.roomDeleted");
              this.showSuccessMessage = true;
              setTimeout(() => {
                this.showSuccessMessage = false;
              }, 2500);
              this.loadTable();
            }
          })
          .catch(error => {
            this.errorMessage = this.$t(
              "profile.manageRooms.deletingRoomError"
            );
            this.showErrorMessage = true;
            setTimeout(() => {
              this.showErrorMessage = false;
            }, 2500);
            console.warn("Error on saveSubrooms", error);
          });
      }
    },
    async deleteRoom(roomId) {
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/deleteRoom`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          room_name: roomId
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            this.$store.dispatch("SAVE_USER_INFO", {
              user: this.$store.state.user,
              userInfo: {
                mainRoom: "",
                savedRooms: []
              }
            });
            this.mainRoom = "";
            this.successMessage = this.$t("profile.manageRooms.roomDeleted");
            this.showSuccessMessage = true;
            setTimeout(() => {
              this.showSuccessMessage = false;
            }, 2500);
          }
        })
        .catch(error => {
          this.errorMessage = this.$t("profile.manageRooms.deletingRoomError");
          this.showErrorMessage = true;
          setTimeout(() => {
            this.showErrorMessage = false;
          }, 2500);
          console.warn("Error on deleteRoom", error);
        });
    },
    openRoom(roomId) {
      if (!this.user) {
        this.$router.push({ path: `/r/${roomId}` });
      } else {
        this.$router.push({ path: `/c/${roomId}` });
      }
    },
    loadTable() {
      this.roomList = [];
      if (this.$store.state.user.user.savedRooms) {
        let hasTimedRoom = false;
        let rooms = this.$store.state.user.user.savedRooms;
        rooms.forEach(room => {
          let slotString = "";
          if (room.isTimedRoom && room.slot) {
            hasTimedRoom = true;

            let timeStartHour = 10 + Math.floor((room.slot - 1) / 2);
            let timeStartMinuteString = (room.slot - 1) % 2 ? ".30" : ".00";
            let timeEndMiuteString =
              timeStartMinuteString === ".30" ? ".00 h" : ".30 h";
            let timeEndHour =
              timeStartMinuteString === ".30"
                ? timeStartHour + 1
                : timeStartHour;
            slotString = `${timeStartHour}${timeStartMinuteString} - ${timeEndHour}${timeEndMiuteString}`;
          }
          if (!room.isPayLinkRoom) {
            this.roomList.push({
              room: room.name,
              online: room.online,
              slot: parseInt(room.slot) || 0,
              slotString: slotString,
              actions: "actions"
            });
          }
        });

        //sort array
        this.roomList.sort((a, b) => {
          if (a.slot - b.slot < 0) {
            return false;
          } else {
            return true;
          }
        });

        //enable new column if any room are slotted
        if (hasTimedRoom) {
          this.tableHeaderRoom = [
            {
              text: "",
              align: "start",
              sortable: true,
              value: "room"
            },
            {
              text: "",
              align: "right",
              sortable: true,
              value: "slotString"
            },
            {
              text: "",
              align: "right",
              sortable: false,
              value: "actions"
            }
          ];
        }
      }
    },
    fingerPrintString() {
      let string =
        "?f=" + this.$store.state.user.fingerPrint + "&ts=" + Date.now();
      return string;
    }
  },
  computed: {
    footerText() {
      return this.$t("profile.subscriptions.rows-per-page");
    },
    sortByText() {
      return this.$t("profile.subscriptions.sort-by-text");
    },
    allText() {
      return this.$t("profile.subscriptions.allText");
    },
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>
<style scoped lang="scss">
.links {
  cursor: pointer;
}
.closeButtonPosition {
  position: absolute;
  right: 25px;
}
.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}
.rowPills {
  width: 60%;
  max-width: 850px;
  margin: 0 auto;
}
.bordered {
  border: 1px solid #ccc;
  min-height: 150px;
  border-radius: 10px;
}
@media screen and (max-width: 1000px) {
  .rowPills {
    width: 100%;
  }
}
@media screen and (max-width: 1470px) and (min-width: 1000.1px) {
  .rowPills {
    width: 80%;
  }
}
</style>
