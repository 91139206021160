<template>
  <v-dialog
    v-model="showStripeConnect"
    persistent
    max-width="600px"
    :attach="getAttach"
  >
    <v-card>
      <HeaderModals :titleModal="'Stripe Connect'"></HeaderModals>

      <v-col cols="12">
        <v-card-text class="text-left">
          {{ $t("plans.payments.warning-for-connect") }} <br /><br />
          {{ $t("plans.payments.warning-for-connect2") }} <br />
          <br />
          <a href="/stripe" target="_blank">
            {{ $t("plans.payments.terms-and-conditions") }}
          </a>
        </v-card-text>
      </v-col>
      <v-card-actions>
        <v-row class="px-2 text-xs-right" align="right">
          <v-col class="text-right">
            <v-btn color="primary" @click="closeModal" class="ma-2">
              {{ $t("paymentModals.close") }}
            </v-btn>

            <v-btn
              color="primary"
              @click="createStripeConnectAccount"
              :loading="isStripeSetupLoading"
              class="ma-2"
            >
              {{ $t("plans.payments.setup-stripe-connect") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
/* eslint-disable */
import { getFirebaseToken } from "../../firebase";
import { isRaMicro } from "../../utils";
import HeaderModals from "./commons/headerModals.vue";
export default {
  name: "stripeConnectModal",
  components: { HeaderModals },
  props: ["showStripeConnect", "closeModal", "isStart"],
  data: () => ({
    isRaMicro: isRaMicro(),
    isStripeSetupLoading: false,
  }),
  methods:{
    async createStripeConnectAccount() {
      this.$emit('loading', true)
      this.isStripeSetupLoading = true;
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/createStripeConnectAccount`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            isRaMicro: this.isRaMicro,
            countryCode: this.user.countryCode || null,
            address1: this.user.street + " " + this.user.address,
            companyName: this.user.company,
            email: this.user.email,
            returnBaseUrl: window.location.origin,
          })
        }
      )
        .then(response => response.json())
        .then(response => {
          this.$emit('loading', false)
          if (response.url) {
            window.open(response.url, "_self");
          } else {
            console.log(response.message);
          }
          this.isStripeSetupLoading = false;
        })
        .catch(error => {
          this.$emit('loading', false)
          console.log(error);
          this.isStripeSetupLoading = false;
        });
    },
    async showTermsConditions() {
      window.open("/stripe", "_blank");
    }
  },
  computed:{
    getAttach(){
      if ( this.isStart ){
        return false;
      }else{
        const element = document.getElementsByClassName(".call")[0];
        if ( element ){
          return element;
        }
      }
    },
    user() {
      return this.$store.state.user.user;
    }
  }
};

</script>
<style scoped lang="scss">
.call{
  .v-dialog{
    .v-card {
      background: rgba(0,0,0,0.6);
      color: #cccccc;
      
    }
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
      background-color: rgb(181 181 181 / 45%) !important;
      color: white !important;
    }
  }
}
</style>

<style lang="scss">
.call{
  .v-dialog{
    .theme--light.v-label{
      color: #cccccc;
    }
    .theme--light.v-input input, .theme--light.v-input textarea{
      color: #cccccc !important
    }
    .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset{
      color: #cccccc !important
    }
  }
}
</style>

