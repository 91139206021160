<template>
  <div class="w-100">
    <v-row flex class="w-100 mx-0 my-0 justify-space-between">
      <v-col class="px-0 py-0" align="left">
        <v-card-title class="headline">
          <v-img
            src="../../../assets/easymeet_icon.svg"
            max-height="30"
            max-width="30"
            contain
          ></v-img>
          <!-- <v-img
            v-else
            src="../../../assets/easymeet_icon.svg"
            max-height="30"
            max-width="30"
            contain
          ></v-img> -->
          <span
            class="font-weight-medium pr-2 pl-4 spanModalCall"
            v-html="titleModal"
          ></span>

          <slot></slot>
        </v-card-title>
      </v-col>
    </v-row>

    <v-divider></v-divider>
  </div>
</template>

<script>
import { isRaMicro } from "../../../utils";
export default {
  components: {},
  props: ["titleModal"],
  data: () => ({
    isRaMicro: isRaMicro()
  }),
  mounted() {},
  methods: {},
  computed: {}
};
</script>

<style scoped lang="scss">
.w-100 {
  width: 100%;
}
.font15 {
  font-size: 15px;
}
.imageLogo {
  display: inline-block;
  vertical-align: middle;
}
</style>
