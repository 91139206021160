var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{style:(_vm.isRaMicro ? 'top: 100px' : 'top: 64px'),attrs:{"height":_vm.isRaMicro ? 'calc(100% - 100px)' : 'calc(100% - 64px)',"fixed":"","dark":!_vm.isRaMicro,"color":_vm.isRaMicro ? '#fff' : '#1976d2',"temporary":false,"width":"320"},model:{value:(_vm.primaryDrawer.model),callback:function ($$v) {_vm.$set(_vm.primaryDrawer, "model", $$v)},expression:"primaryDrawer.model"}},[_c('v-list',[_c('v-list-item',{staticClass:"text-left",on:{"click":function($event){_vm.showSection('home');
        _vm.handlerClickBars();}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('font-awesome-icon',{staticClass:"mr-4 w20",style:({ fontSize: 18 }),attrs:{"icon":['fal', 'home']}}),_vm._v(" "+_vm._s(_vm.$t("links.home"))+" ")],1)],1)],1),_c('v-list-item',{staticClass:"text-left",on:{"click":function($event){_vm.showSection('addressbook');
        _vm.handlerClickBars();}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('font-awesome-icon',{staticClass:"mr-4 w20",style:({ fontSize: 18 }),attrs:{"icon":['fal', 'address-book']}}),_vm._v(" "+_vm._s(_vm.$t("profile.addressBook.addressbook"))+" ")],1)],1)],1),_c('v-list-item',{staticClass:"text-left",on:{"click":function($event){_vm.showSection('info');
        _vm.handlerClickBars();}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('font-awesome-icon',{staticClass:"mr-4 w20",style:({ fontSize: 18 }),attrs:{"icon":['fal', 'address-card']}}),_vm._v(" "+_vm._s(_vm.$t("sidebar.personal-info"))+" ")],1)],1)],1),_c('v-list-item',{staticClass:"text-left",on:{"click":function($event){_vm.showSection('rooms');
        _vm.handlerClickBars();}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('font-awesome-icon',{staticClass:"mr-4 w20",style:({ fontSize: 18 }),attrs:{"icon":['fal', 'sitemap']}}),_vm._v(" "+_vm._s(_vm.$t("sidebar.manage-rooms"))+" ")],1)],1)],1),(!_vm.isRaMicro)?_c('v-list-item',{staticClass:"text-left",on:{"click":function($event){_vm.showSection('subscriptions');
        _vm.handlerClickBars();}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('font-awesome-icon',{staticClass:"mr-4 w20",style:({ fontSize: 18 }),attrs:{"icon":['fal', 'money-check-edit-alt']}}),_vm._v(" "+_vm._s(_vm.$t("sidebar.subscriptions"))+" ")],1)],1)],1):_vm._e(),_c('v-list-item',{staticClass:"text-left",on:{"click":function($event){_vm.showSection('payments');
        _vm.handlerClickBars();}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('font-awesome-icon',{staticClass:"mr-4 w20",style:({ fontSize: 18 }),attrs:{"icon":['fal', 'hands-usd']}}),_vm._v(" "+_vm._s(_vm.$t("sidebar.client-payments"))+" ")],1)],1)],1),_c('v-list-item',{staticClass:"text-left",on:{"click":function($event){_vm.showSection('roomHistory');
        _vm.handlerClickBars();}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('font-awesome-icon',{staticClass:"mr-4 w20",style:({ fontSize: 18 }),attrs:{"icon":['fal', 'history']}}),_vm._v(" "+_vm._s(_vm.$t("profile.roomHistory.titel"))+" ")],1)],1)],1),(!_vm.isRaMicro)?_c('v-list-item',{staticClass:"text-left",on:{"click":function($event){_vm.showSection('affilate');
        _vm.handlerClickBars();}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('font-awesome-icon',{staticClass:"mr-4 w20",style:({ fontSize: 18 }),attrs:{"icon":['fal', 'stars']}}),_vm._v(" "+_vm._s(_vm.$t("sidebar.affiliate"))+" ")],1)],1)],1):_vm._e(),_c('v-list-item',{staticClass:"text-left",on:{"click":function($event){return _vm.$router.replace('/')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('font-awesome-icon',{staticClass:"mr-4 w20",style:({ fontSize: 18 }),attrs:{"icon":['fal', 'arrow-left']}}),_vm._v(" "+_vm._s(_vm.$t("links.home"))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }