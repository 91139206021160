import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/messaging";

const apiKey = process.env.VUE_APP_FIREBASE_KEY;
const authDomain = `${process.env.VUE_APP_FIREBASE_PROJECTID}.firebaseapp.com`;
const databaseURL = `https://${process.env.VUE_APP_FIREBASE_PROJECTID}.firebaseio.com`;
const projectId = process.env.VUE_APP_FIREBASE_PROJECTID;
const storageBucket = `${process.env.VUE_APP_FIREBASE_PROJECTID}.appspot.com`;
const messagingSenderId = process.env.VUE_APP_FIREBASE_MESSAGESENDERID;
const appId = "1:70774131000:web:e64c8db6962f5b53e259cd";

const config = {
  apiKey,
  authDomain,
  databaseURL,
  projectId,
  storageBucket,
  messagingSenderId,
  appId
};

firebase.initializeApp(config);

// To remove firebase warning when trying to add record
// Using Cloud Firestore
const firestore = firebase.firestore();
const storageRef = firebase.storage().ref();
const settings = {
  /* your settings... */
};
firestore.settings(settings);

export default firebase;

export function uploadFile(file, filename) {
  return new Promise((resolve, reject) => {
    storageRef
      .child(filename)
      .put(file)
      .then(() => {
        return getDownloadUrl(filename);
      })
      .then(url => {
        resolve(url);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function deleteFile(filename) {
  return new Promise((resolve, reject) => {
    storageRef
      .child(filename)
      .delete()
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getDownloadUrl(filename) {
  return new Promise((resolve, reject) => {
    storageRef
      .child(filename)
      .getDownloadURL()
      .then(url => {
        resolve(url);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function onAuthenticationChanged() {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(
      user => {
        resolve(user);
        return;
      },
      error => {
        reject(error);
      }
    );
  });
}

export function getUser() {
  return firebase.auth().currentUser;
}

export function checkActionCode({ code }) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .verifyPasswordResetCode(code)
      .then(() => {
        resolve(true);
        return;
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function changePassword({ code, password }) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        resolve(true);
        return;
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function sendPaswordReset({ email, languageCode }) {
  return new Promise((resolve, reject) => {
    firebase.auth().languageCode = languageCode;
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        resolve(true);
        return;
      })
      .catch(error => {
        reject(error);
      });
  });
}
export function signupUser({ email, password }) {
  return new Promise((resolve, reject) => {
    const createdUser = firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    createdUser
      .then(user => {
        getUser().sendEmailVerification();
        resolve(user);
        return;
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function sendEmailVerification({ languageCode }) {
  return new Promise((resolve, reject) => {
    firebase.auth().languageCode = languageCode;
    getUser()
      .sendEmailVerification()
      .then(() => {
        resolve(true);
        return;
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function verifyEmail(actionCode) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .applyActionCode(actionCode)
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function signInUser({ email, password }) {
  return new Promise((resolve, reject) => {
    const authenticatedUser = firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    authenticatedUser
      .then(user => {
        resolve(user);
        return;
      })
      .catch(error => {
        reject(error);
      });
  });
}

const userModel = ({ user }) => {
  const provider = user.providerData[0].providerId;
  const { uid, displayName, email, phoneNumber, photoURL } = user;
  return {
    uid,
    displayName,
    email,
    phoneNumber,
    photoURL,
    provider,
    emailVerificationRequired: true,
    registeredSince: Date.now()
  };
};

export function addUser(user, ref) {
  return new Promise((resolve, reject) => {
    // Using Cloud Firestore
    const createUser = userModel(user);

    if (ref) {
      const userDoc = firebase
        .firestore()
        .collection("users")
        .doc(user.user.uid)
        .set({ ...createUser, ref }, { merge: true });

      userDoc
        .then(() => {
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });
    } else {
      // Prevents social logins from overriding ref
      const userDoc = firebase
        .firestore()
        .collection("users")
        .doc(user.user.uid)
        .set({ ...createUser }, { merge: true });

      userDoc
        .then(() => {
          resolve(user);
        })
        .catch(error => {
          reject(error);
        });
    }
  });
}

export function fetchUser(user) {
  return new Promise((resolve, reject) => {
    var userRef = firebase
      .firestore()
      .collection("users")
      .doc(user.uid);
    userRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          resolve(null);
        } else {
          resolve(doc.data());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function signOutUser() {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        resolve(true);
        return;
      })
      .catch(error => {
        reject(error);
      });
  });
}

// Listen to authStateChange once once, awaitably.
const authStateChangedOnce = (timeout, needsUser) => {
  return new Promise((resolve, reject) => {
    try {
      const auth = firebase.auth();
      let timeoutHolder;

      const unlisten = auth.onAuthStateChanged(user => {
        if (needsUser && user === null) return;
        if (timeoutHolder) clearTimeout(timeoutHolder);
        unlisten();
        resolve(user);
      });

      if (timeout) {
        timeoutHolder = setTimeout(() => {
          unlisten();
          reject("Timeout");
        }, timeout);
      }
    } catch (err) {
      reject(err);
    }
  });
};

// Call authStateChangedOnce once in global promise at start, cos its also the firebase ready indicator. I know.
export const firebaseReadyPromise = authStateChangedOnce().catch(() => {}); // Prevent uncaught in promise errorlogs.

export const getFirebaseToken = async (forceRefresh = false) => {
  await firebaseReadyPromise;
  if (firebase.auth().currentUser) {
    return await firebase.auth().currentUser.getIdToken(forceRefresh);
  }
  return undefined;
};

export const getFirebaseClaims = async (forceRefresh = false) => {
  await firebaseReadyPromise;
  if (firebase.auth().currentUser) {
    const idTokenResult = await firebase
      .auth()
      .currentUser.getIdTokenResult(forceRefresh);
    return idTokenResult.claims;
  }
  return undefined;
};

/*****************
Social logins
******************/

function getProvider({ provider }) {
  switch (provider) {
    case "GITHUB":
      return new firebase.auth.GithubAuthProvider();
    case "FACEBOOK":
      return new firebase.auth.FacebookAuthProvider();
    case "TWITTER":
      return new firebase.auth.TwitterAuthProvider();
    case "GOOGLE":
      return new firebase.auth.GoogleAuthProvider();
    default:
      console.error(`No provider found for ${provider}`); // eslint-disable-line
      break;
  }
}

export function signInWithSocial({ provider, isMobile = false }) {
  // TODO: Validate the type of device redirect is preffered on mobile
  return new Promise((resolve, reject) => {
    const useMobileLogin = isMobile;
    var firebaseProvider = getProvider({ provider });

    if (useMobileLogin) {
      firebase.auth().signInWithRedirect(firebaseProvider);
      firebase
        .auth()
        .getRedirectResult()
        .then(function(result) {
          if (result.credential) {
            // This gives you a Access Token. You can use it to access the API.
            var token = result.credential.accessToken;
            // ...
          }
          // The signed-in user info.
          var user = result.user;
          resolve({ user, token });
        })
        .catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          reject({ errorCode, errorMessage, email, credential });
        });
    } else {
      firebase
        .auth()
        .signInWithPopup(firebaseProvider)
        .then(function(result) {
          // This gives you a Access Token. You can use it to access the API.
          var token = result.credential.accessToken;
          // The signed-in user info.
          var user = result.user;
          resolve({ user, token });
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
}

export function saveUserAPISettings({ user, settings }) {
  return new Promise((resolve, reject) => {
    // Using Cloud Firestore
    const userDoc = firebase
      .firestore()
      .collection("users")
      .doc(user.user.uid)
      .set(settings, { merge: true });

    userDoc
      .then(() => {
        resolve(user);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function saveUserTransactions({ user, transactionDetails }) {
  console.log("user", user);
  console.log("transactionDetails", transactionDetails);

  return new Promise((resolve, reject) => {
    // Using Cloud Firestore
    const userDoc = firebase
      .firestore()
      .collection("users")
      .doc(user.user.uid)
      .collection("transactions")
      .doc(transactionDetails.id)
      .set({ ...transactionDetails, ts: Date.now() });

    userDoc
      .then(() => {
        resolve(user);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function fetchUserTransactions(user) {
  return new Promise((resolve, reject) => {
    var userRef = firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("transactions");
    userRef
      .get()
      .then(doc => {
        if (doc.empty) {
          resolve(null);
        } else {
          const tmpObj = {};
          doc.docs.forEach(transaction => {
            tmpObj[transaction.id] = transaction.data();
          });
          resolve(tmpObj);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function fetchUserSubscriptions(user) {
  return new Promise((resolve, reject) => {
    var userRef = firebase
      .firestore()
      .collection("subscriptions")
      .doc(user.uid)
      .collection("history");
    userRef
      .get()
      .then(doc => {
        if (doc.empty) {
          resolve(null);
        } else {
          const tmpObj = {};
          doc.docs.forEach(subscription => {
            tmpObj[subscription.id] = subscription.data();
          });
          resolve(tmpObj);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function saveUserInfo({ user, userInfo }) {
  return new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(user.user.uid)
      .set(userInfo, { merge: true })
      .then(() => {
        resolve(user);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function fetchRoomStatus(mainRoomID, subRoomID) {
  return new Promise(resolve => {
    var roomRef = firebase
      .firestore()
      .collection("room")
      .doc("" + mainRoomID);
    roomRef
      .get()
      .then(doc => {
        if (doc.empty) {
          resolve(null);
        } else {
          if (subRoomID) {
            let subRooms = doc.data().subroom || [];
            let index = subRooms.findIndex(subRooms => {
              if (subRooms.name === subRoomID) {
                return true;
              }
            });
            if (index != -1) {
              resolve({
                owner: doc.data().owner,
                email: doc.data().email,
                online: subRooms[index].online,
                locked: subRooms[index].locked || false
              });
            } else {
              resolve({
                owner: doc.data().owner,
                online: false,
                locked: false
              });
            }
          } else {
            resolve({
              owner: doc.data().owner,
              email: doc.data().email,
              online: doc.data().online,
              locked: false
            });
          }
        }
      })
      .catch(() => {
        resolve({
          owner: "none",
          email: null,
          online: false,
          locked: false
        });
      });
  });
}

export function reauthenticateUser(password) {
  return new Promise((resolve, reject) => {
    let user = getUser();

    let credentials = firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    user
      .reauthenticateWithCredential(credentials)
      .then(() => {
        resolve(true);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;
export function getDeviceToken() {
  return new Promise((resolve, reject) => {
    if (!messaging) {
      resolve(false);
    }
    messaging
      .getToken()
      .then(token => {
        resolve(token);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function getPushNotificationDevice(user) {
  return new Promise((resolve, reject) => {
    const userRef = firestore.collection("pushNotification").doc(user);

    userRef
      .get()
      .then(doc => {
        if (doc.empty) {
          resolve(null);
        } else {
          resolve(doc.data().device);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getStripeSubscription(user, isRaMicro) {
  return new Promise((resolve, reject) => {
    let collection = "subscriptions_stripe_testmode";
    if (process.env.VUE_APP_STRIPE_MODE === "live") {
      collection = "subscriptions_stripe";
    }
    if (isRaMicro) {
      collection += "_ra";
    }

    const doc = firestore
      .collection(collection)
      .doc(user.uid)
      .collection("subscription")
      .doc("current");
    doc
      .get()
      .then(results => {
        resolve(results.data());
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getStripeInvoice(user, isRaMicro) {
  return new Promise((resolve, reject) => {
    let collection = "subscriptions_stripe_testmode";
    if (process.env.VUE_APP_STRIPE_MODE === "live") {
      collection = "subscriptions_stripe";
    }
    if (isRaMicro) {
      collection += "_ra";
    }

    const invoiceRef = firestore
      .collection(collection)
      .doc(user.uid)
      .collection("invoice");
    invoiceRef
      .get()
      .then(invoices => {
        const results = [];
        invoices.forEach(invoice => {
          results.push(invoice.data());
        });
        resolve(results);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getStripeConnectInvoice(user, isRaMicro) {
  return new Promise((resolve, reject) => {
    let collection = "merchant_stripe_testmode";
    if (process.env.VUE_APP_STRIPE_MODE === "live") {
      collection = "merchant_stripe";
    }
    if (isRaMicro) {
      collection += "_ra";
    }

    const invoiceRef = firestore
      .collection(collection)
      .doc(user.user.uid)
      .collection("invoice");
    invoiceRef
      .get()
      .then(invoices => {
        const results = [];
        invoices.forEach(invoice => {
          results.push(invoice.data());
        });
        resolve(results);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function fetchUserRefClickStats(user) {
  return new Promise((resolve, reject) => {
    var statDoc = firebase
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stats")
      .doc("ref_click");
    statDoc
      .get()
      .then(doc => {
        if (!doc.exists) {
          resolve(null);
        } else {
          resolve(doc.data());
        }
      })
      .catch(error => {
        reject(error);
      });
  });
}
