var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"cardProfile",attrs:{"flat":""}},[(_vm.isMobile)?_c('span',{staticClass:"text-right closeButtonPosition links",on:{"click":function($event){return _vm.showSection('home')}}},[_vm._v("x")]):_vm._e(),_c('v-row',{staticClass:"rowPills"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"pb-2"},[_vm._v(" "+_vm._s(_vm.$t("profile.addressBook.addressbook"))+" ")]),_c('p',[_vm._v(_vm._s(_vm.$t("profile.addressBook.subtitle")))])]),(_vm.isStarter && !_vm.isLoadingUserClaim && !_vm.isRaMicro)?_c('v-col',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('profile.addressBook.subtitleStarter'))}})]):_vm._e(),(!_vm.isStarter || _vm.isRaMicro)?_c('v-col',{staticClass:"px-0",staticStyle:{"text-align":"left"},attrs:{"cols":"12"}},[_c('div',{staticClass:"bordered pa-5"},[_c('h2',{staticClass:"pb-1 title"},[_vm._v(" "+_vm._s(_vm.$t("profile.addressBook.contacts"))+" "),_c('v-btn',{staticClass:"buttonAddNew",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.showModalAddNewContact()}}},[_vm._v(" "+_vm._s(_vm.$t("profile.addressBook.addNew"))+" ")])],1),_c('v-text-field',{staticClass:"mt-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('profile.addressBook.search'),"single-line":"","hide-details":"","dense":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',[_c('v-data-table',{attrs:{"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"headers":_vm.getTableHeaders,"footer-props":{
              'items-per-page-text': _vm.footerText,
              'items-per-page-all-text': _vm.allText
            },"header-props":{
              'sort-by-text': _vm.sortByText
            },"items":_vm.contacts,"search":_vm.search,"loading":_vm.loadingDataTable,"loading-text":"","mobile-breakpoint":"750"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.phone)+" "),_c('font-awesome-icon',{directives:[{name:"show",rawName:"v-show",value:(item.hasWhatsapp),expression:"item.hasWhatsapp"}],style:({ fontSize: 15 }),attrs:{"icon":['fab', 'whatsapp']}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.editContact(item, true)}}},'v-btn',attrs,false),on),[(item.note)?_c('font-awesome-icon',{staticClass:"yellow--text",style:({ fontSize: 15 }),attrs:{"icon":['fas', 'sticky-note']}}):_c('font-awesome-icon',{staticClass:"yellow--text text--darken-4",style:({ fontSize: 15 }),attrs:{"icon":['fas', 'sticky-note']}})],1)]}}],null,true)},[(item.note)?_c('span',[_vm._v(_vm._s(item.note))]):_c('span',[_vm._v(_vm._s(_vm.$t("profile.addressBook.addNotes")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"target":"_blank","icon":"","color":"primary","loading":item.loadingDirectCall,"small":""},on:{"click":function($event){return _vm.handleDirectCall(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{style:({ fontSize: 15 }),attrs:{"icon":['fas', 'phone-alt']}})],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("profile.addressBook.direct-call")))])]),(_vm.isMobile)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.shareLinkOnMobile(_vm.generateDirectCallLink(item))}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{style:({ fontSize: 15 }),attrs:{"icon":['far', 'share']}})],1)]}}],null,true)},[_c('span')]):_vm._e(),(_vm.userHasPayments)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"target":"_blank","icon":"","color":"primary","loading":item.creatingPayLink,"small":""},on:{"click":function($event){return _vm.handlePaylinkCall(item)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{style:({ fontSize: 15 }),attrs:{"icon":['far', 'money-bill']}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("profile.addressBook.paylink-call")))])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"target":"_blank","icon":"","color":"success","small":""},on:{"click":function($event){return _vm.editContact(item, false)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{style:({ fontSize: 15 }),attrs:{"icon":['far', 'pencil']}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("profile.addressBook.edit-contact")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"target":"_blank","icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.handleDeleteContact(item.key)}}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{style:({ fontSize: 15 }),attrs:{"icon":['fal', 'trash']}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("profile.addressBook.deleteContact")))])])]}}],null,false,2604536643)},[_c('template',{slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t("profile.addressBook.no-contacts"))+" ")])],2)],1)],1)]):_vm._e()],1),_c('AddNewContactModal',{attrs:{"loading":_vm.loading,"showModalAddContact":_vm.showModalAddContact,"closeModalAddNewContact":_vm.closeModalAddNewContact,"insertContact":_vm.insertContact,"contacts":_vm.contacts}}),_c('EditContactModal',{attrs:{"loading":_vm.loading,"showModalEditContact":_vm.showModalEditContact,"closeModalEditContact":_vm.closeModalEditContact,"handleEditContact":_vm.handleEditContact,"contact":_vm.editedContact,"contacts":_vm.contacts,"isEditingNotesOnly":_vm.isEditingNotesOnly}}),_c('DeleteContactModal',{attrs:{"loading":_vm.loading,"showDeleteContact":_vm.showDeleteContact,"closeModalDeleteContact":_vm.closeModalDeleteContact,"deleteContact":_vm.deleteContact}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }