<template>
  <v-app>
    <notifications group="main" :duration="-1" position="top left">
      <template slot="body" slot-scope="{ item, close }">
        <!-- Default slot template -->
        <font-awesome-icon
          :icon="['far', 'times']"
          @click="close()"
          :style="{
            fontSize: 17,
            color: '#ffffff',
            position: 'absolute',
            top: '5px',
            right: '15px',
            cursor: 'pointer'
          }"
        />
        <div
          class="my-notification"
          @click="
            openLink(item.data.link);
            close();
          "
        >
          <div
            v-if="item.title"
            class="notification-title"
            v-html="item.title"
          ></div>
          <div class="notification-content" v-html="item.text"></div>
        </div>
      </template>
    </notifications>
    <v-main :class="{ inCall: isInCall }">
      <v-progress-linear
        absolute
        v-if="loading"
        color="primary accent-4"
        indeterminate
        rounded
        height="5"
      ></v-progress-linear>
      <ios-banner
        :showBanner="showBanner"
        :closeBanner="closeBanner"
      ></ios-banner>
      <Header v-if="showHeader && !loading" />
      <router-view />
      <Footer v-if="showFooter" />
    </v-main>
    <!-- <easy-beacon></easy-beacon> -->
  </v-app>
</template>
<script>
import Header from "./components/header.vue";
import Footer from "./components/footer.vue";
import IosBanner from "./components/iosBanner.vue";
import { messaging, getDeviceToken } from "@/firebase";
export default {
  name: "App",
  components: { Header, Footer, IosBanner },
  data: () => ({
    showBanner: false,
    closedBanner: false
  }),
  created() {
    if (this.$workbox) {
      this.$workbox.addEventListener("waiting", () => {
        this.updateApp();
      });
    }

    setTimeout(() => {
      this.enableNotification();
    }, 1000);
  },
  mounted() {
    this.toTop();
    this.showPWABannerForIos();

    messaging.onMessage(payload => {
      const notificationTitle = payload.data.title;
      const notificationOptions = {
        body: payload.data.body,
        icon: payload.data.icon,
        requireInteraction: payload.data.requireInteraction,
        tag: payload.data.tag
      };
      if (!("Notification" in window)) {
        console.log("This browser does not support system notifications");
      } else {
        if (Notification.permission === "granted") {
          try {
            console.log(this.$route);
            if (
              this.$route.name !== "call" &&
              this.$route.name !== "call_subroom"
            ) {
              //Send notification if not in call
              this.$notify({
                group: "main",
                title: payload.data.title,
                text: payload.data.body,
                data: { link: payload.data.tag }
              });

              if (document.hidden) {
                let notification = new Notification(
                  notificationTitle,
                  notificationOptions
                );
                notification.onclick = function(event) {
                  event.preventDefault();
                  window.open(payload.data.tag, "_self");
                  notification.close();
                };
              }
            } else {
              //Send notification if not in room already
              if (this.$route.params.roomid === payload.data.roomid) {
                if (
                  this.$route.params.subRoom &&
                  this.$route.params.subRoom !== payload.data.subroom
                ) {
                  this.$notify({
                    group: "main",
                    title: payload.data.title,
                    text: payload.data.body,
                    data: { link: payload.data.tag }
                  });

                  if (document.hidden) {
                    let notification = new Notification(
                      notificationTitle,
                      notificationOptions
                    );
                    notification.onclick = function(event) {
                      event.preventDefault();
                      window.open(payload.data.tag, "_self");
                      notification.close();
                    };
                  }
                }
              } else {
                this.$notify({
                  group: "main",
                  title: payload.data.title,
                  text: payload.data.body,
                  data: { link: payload.data.tag }
                });

                if (document.hidden) {
                  let notification = new Notification(
                    notificationTitle,
                    notificationOptions
                  );
                  notification.onclick = function(event) {
                    event.preventDefault();
                    window.open(payload.data.tag, "_self");
                    notification.close();
                  };
                }
              }
            }
          } catch (err) {
            try {
              navigator.serviceWorker.ready.then(function(registration) {
                registration.showNotification(
                  notificationTitle,
                  notificationOptions
                );
              });
            } catch (err1) {
              console.log(err1.message);
            }
          }
        }
      }
    });
  },
  watch: {
    "$route.path": {
      immediate: true,
      deep: true,
      handler: function(val) {
        const hasClosedBanner = localStorage.getItem("hasClosedBanner");
        if (val.indexOf("/c/") !== -1 && this.showBanner) {
          this.showBanner = false;
        } else if (val.indexOf("/c/") == -1 && !this.closedBanner) {
          if (
            this.isIos() &&
            !this.isInStandaloneMode() &&
            this.isSafari() &&
            !hasClosedBanner
          ) {
            this.showBanner = true;
          }
        }
      }
    }
  },
  methods: {
    async updateApp() {
      this.showModalNewVersion = false;
      await this.$workbox.messageSW({ type: "SKIP_WAITING" });
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    isIos() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    },
    isSafari() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("safari") != -1) {
        if (ua.indexOf("chrome") > -1) {
          return false; // No Safari
        } else {
          return true; // Safari
        }
      }
    },
    isInStandaloneMode() {
      return "standalone" in window.navigator && window.navigator.standalone;
    },
    showPWABannerForIos() {
      const hasClosedBanner = localStorage.getItem("hasClosedBanner");
      if (
        this.isIos() &&
        !this.isInStandaloneMode() &&
        this.$route.path.indexOf("/c/") == -1 &&
        this.isSafari() &&
        !hasClosedBanner
      ) {
        this.showBanner = true;
        localStorage.setItem("hasClosedBanner", Date.now());
      }
    },
    closeBanner() {
      this.closedBanner = true;
      this.showBanner = false;
    },
    async enableNotification() {
      let token;
      try {
        token = await getDeviceToken();
        console.log("token", token);
      } catch (error) {
        console.log("Notification permission was not enabled: ", error);
      }
    },
    openLink(link) {
      window.open(link, "_self");
    }
  },
  computed: {
    showHeader() {
      return (
        this.$route.path.indexOf("/c/") == -1 &&
        this.$route.path.indexOf("/et/") == -1 &&
        this.$route.path.indexOf("/ad/") == -1 &&
        this.$route.path.indexOf("/sc/") == -1 &&
        this.$route.path !== "/profile"
      );
    },
    showFooter() {
      return (
        this.$route.path.indexOf("/c/") == -1 && this.$route.path !== "/profile"
      );
    },
    isInCall() {
      return this.$route.path.indexOf("/c/") != -1;
    },
    user() {
      return this.$store.state.user.user;
    },
    loading() {
      return !!this.$store.state.user.loading;
    }
  }
};
</script>

<style scoped lang="scss">
html,
body,
#app,
.v-application--wrap,
.v-main,
.call {
  height: 100%;
  min-height: 100% !important;
  // overflow-x: auto; //~Vanne pls check this, causing double scroll
}
.v-application {
  font-family: "Muli", sans-serif;
}
a {
  text-decoration: none;
}
#app {
  font-family: "Muli", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.loading-screen {
  padding-bottom: 10%;
}
.hidden {
  display: none;
}
.v-application.body-1,
.body-1 {
  font-family: "Muli", sans-serif !important;
}
.app-main-blue {
  color: #1976d2;
}

.iosPWA {
  position: fixed;
  bottom: 0;
  line-height: 1.2;
  font-size: 13px;
  text-align: left;
  width: 100%;
  left: 0;
  padding: 8px;
  background: white;
  border-top: 4px solid #1876d1;
  border-radius: 0px !important;
  margin: 0px;
  align-items: flex-end;
  z-index: 9;
  .v-img {
    height: 42px;
    width: 27px;
    display: inline-block;
  }
}

@media only screen and (max-height: 620px) {
  .v-main:not(.inCall) {
    height: 630px !important;
  }
}

.my-notification {
  // styling
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 12px;
  color: #ffffff;

  // default (blue)
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  // types (green, amber, red)
  &.success {
    background: #68cd86;
    border-left-color: #42a85f;
  }

  &.warn {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &.error {
    background: #e54d42;
    border-left-color: #b82e24;
  }
}

.vue-notification-wrapper {
  position: relative;
}
</style>

<style lang="scss">
.genericAlerts {
  position: fixed !important;
  top: 0;
  z-index: 9;
  top: 10px;
  right: 10px;
  max-width: 450px;
  word-break: break-word;
}
.v-dialog__content {
  .v-card__subtitle,
  .v-card__text {
    font-size: 1.2rem;
  }
  .v-card__actions {
    padding: 8px 15px 15px 15px !important;
  }
}

@media only screen and (max-width: 760px) {
  .v-dialog {
    margin: 0px !important;
  }
}
</style>
