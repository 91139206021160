<template>
  <v-container class="containerStartPage">
    <!-- <Header /> -->
    <v-row class="text-center">
      <div class="room-selection text-left">
        <v-img
          v-if="!isRaMicro"
          class="mb-7 logo"
          contain
          src="../assets/easymeet_logo-b.svg"
          @click="goToMainPage"
        ></v-img>
        <v-img
          v-else
          class="mb-7 logo"
          contain
          src="../assets/easymeet_logo-b.svg"
          @click="goToMainPage"
        ></v-img>
        <v-form v-model="valid" lazy-validation>
          <v-row class="mx-0">
            <v-col cols="12" class="px-0">
              <v-text-field
                :label="$t('generics.email')"
                v-on:keyup.enter="onLogin"
                required
                solo
                dense
                hide-details
                v-model="email"
                @focus="clearErrors"
                :rules="emailRules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-text-field
                :label="$t('generics.password')"
                v-on:keyup.enter="onLogin"
                required
                solo
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                dense
                hide-details
                v-model="password"
                @focus="clearErrors"
                :rules="passRules"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="px-0">
              <v-btn
                color="primary"
                class="w-100"
                depressed
                :loading="loading"
                @click="onLogin"
                :disabled="!valid"
              >
                {{ $t("generics.login") }}
              </v-btn>
            </v-col>
            <v-col cols="12" class="px-0 pb-0 text-center">
              <p class="mb-4">
                <router-link to="/forgot-password">{{
                  $t("generics.forgot-password")
                }}</router-link>
              </p>
              <p class="mb-0">
                {{ $t("generics.dont-have-account") }}
                <router-link to="/register">{{
                  $t("generics.sign-up")
                }}</router-link>
              </p>
            </v-col>
          </v-row>
        </v-form>
        <div class="h-divider">
          <div class="shadow"></div>
          <div class="text">{{ $t("generics.or") }}</div>
        </div>
        <v-row class="mt-4 mx-0">
          <v-col cols="6" xs="12" class="pl-0">
            <v-btn
              :class="{ social: true, twitter: true, 'w-100': true }"
              depressed
              color="#55acee"
              @click="onSignInWithSocial('TWITTER')"
            >
              <font-awesome-icon
                class="social-icon"
                :icon="['fab', 'twitter']"
                :style="{ fontSize: 15 }"
              />
              <span class="social-label">Twitter</span>
              <div class="fill" />
            </v-btn>
          </v-col>

          <v-col cols="6" xs="12" class="pr-0">
            <v-btn
              :class="{ social: true, facebook: true, 'w-100': true }"
              color="#3b5998"
              depressed
              @click="onSignInWithSocial('FACEBOOK')"
            >
              <font-awesome-icon
                class="social-icon"
                :icon="['fab', 'facebook']"
                :style="{ fontSize: 15 }"
              />
              <span class="social-label">Facebook</span>
              <div class="fill" />
            </v-btn>
          </v-col>

          <v-col cols="6" xs="12" class="pl-0">
            <v-btn
              :class="{ social: true, github: true, 'w-100': true }"
              color="#444"
              depressed
              @click="onSignInWithSocial('GITHUB')"
            >
              <font-awesome-icon
                class="social-icon"
                :icon="['fab', 'github']"
                :style="{ fontSize: 15 }"
              />
              <span class="social-label">Github</span>
              <div class="fill" />
            </v-btn>
          </v-col>

          <v-col cols="6" xs="12" class="pr-0">
            <v-btn
              :class="{ social: true, google: true, 'w-100': true }"
              color="#dd4b39"
              depressed
              @click="onSignInWithSocial('GOOGLE')"
            >
              <font-awesome-icon
                class="social-icon"
                :icon="['fab', 'google']"
                :style="{ fontSize: 15 }"
              />
              <span class="social-label">Google</span>
              <div class="fill" />
            </v-btn>
          </v-col>
          <v-col cols="12" class="px-0">
            <v-alert :value="hasError" type="error" class="genericAlerts">
              {{ error }}
            </v-alert>
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { isMobile, isRaMicro } from "../utils";
import { setLanguage, getTranslation } from "firebase-error-translator";

/* eslint-disable */
export default {
  name: "Login",
  components: {  },
  data: () => ({
    email: '',
    password: '',
    isMobile: isMobile(),
    isRaMicro: isRaMicro(),
    valid: false,
    passRules: [v => !!v || "Password is required"],
    emailRules: [
        v => !!v || "E-mail is required",
        v =>
          // eslint-disable-next-line no-useless-escape
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail is invalid"
      ],
      showPassword: false
  }),
  mounted() {
    this.clearErrors(),
    this.toTop()
  },
  methods: {
     toTop() {
      this.$vuetify.goTo(0);
    },
    clearErrors(){
      if (this.error) {
        this.$store.commit("USER_CLEAR_REQUEST");
      }
    },
    onLogin() {
      this.clearErrors();
      this.$store.dispatch("USER_SIGNIN", {
        email: this.email,
        password: this.password
      });
    },
    onSignInWithSocial(provider) {
      this.$store.dispatch("USER_SIGNIN_SOCIAL", {
        provider,
        isMobile: this.isMobile
      });
    },
    goToMainPage() {
      this.$router.replace("/");
    },
  },
  watch: {
    user(newValue) {
      if (newValue) {
        this.$router.replace("/profile");
      }
    },
  },
  computed:{
    user() {
      return this.$store.state.user.user;
    },
    error() {
      if (this.$store.state.user.user_error_code) {
        let lang = navigator.language || navigator.userLanguage;
        setLanguage(lang.slice(0, 2))
        return getTranslation(this.$store.state.user.user_error_code)
      } else {
        // return this.$store.state.user.user_error;
      }
    },
    loading() {
      return this.$store.state.user.user_request;
    },
    hasError() {
      return !!this.$store.state.user.user_error;
    }
  }
};

</script>

<style scoped lang="scss">
@media only screen and (max-width: 700px) {
  .logo{
    max-width: 82vw;
    margin:0 auto;
  }
}
.w-100{
  width: 100%;
}
.room-selection {
  margin: 0 auto 0 auto; 
  padding: 1em 1.5em 1.3em 1.5em;
  max-width: 400px;
  position: relative;
  top: 7em;
}
.logo {
  cursor: pointer;
  max-height: 66px;
}
.h-divider{
    margin: auto;
    margin-top: 40px;
    width: 100%;
    position: relative;
  .shadow {
    overflow: hidden;
    height: 0px;
    border-top: 1px solid;
  }
  
  .text{
    width: 25%;
    height: auto;
    padding: 2px;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    left: 0;
    margin-left: 0;
    background: white;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: -13px;
  }
}
.social > div.v-btn__content {
  display: flex;
  justify-content: flex-start;
  padding-left: 20px;
}
.social-label {
  padding-left: 10px;
}
.google {
  background-color: #dd4b39;
  border: 2px solid #dd4b39;
  color: #fff;
}
.twitter {
  background-color: #55acee;
  border: 2px solid #55acee;
  color: #fff;
}
.facebook {
  background-color: #3b5998;
  border: 2px solid #3b5998;
  color: #fff;
}
.github {
  background-color: #444;
  border: 2px solid #444;
  color: #fff;
}
.alertSuccess {
  background-color: black;
  color: white;
  max-width: 170px;
  top: 15px;
  left: calc(100% - 170px);
  padding: 5px;
  font-size: 14px;
}
</style>
