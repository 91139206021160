<template>
  <v-container class="containerStartPage">
    <template v-if="!showCallComponent">
      <div
        class="d-md-flex mt-md-0 align-center"
        :class="!isMobile ? 'height-100' : ''"
        :style="isMobile ? 'margin-top: 74px' : ''"
      >
        <v-row>
          <v-col class="text-center px-8  pb-0" cols="12">
            <v-img
              v-if="!isRaMicro"
              class="logo mb-2"
              contain
              src="../assets/easymeet_logo-b.svg"
            ></v-img>
            <v-img
              v-else
              class="logo mb-2"
              contain
              src="../assets/easymeet_logo-b.svg"
            ></v-img>
          </v-col>
          <v-col class="text-center" cols="12">
            <h1>{{ $t("ra-version.home-page.title") }}</h1>
            <h2 class="text-h5">
              {{ $t("ra-version.home-page.text1") }}
            </h2>
            <p style="color: red">
              <em>{{ $t("ra-version.free") }}</em>
            </p>
            <h3 class="text-h5">
              {{ $t("ra-version.home-page.text2") }}
            </h3>
            <p style="color: red">{{ $t("ra-version.home-page.text3") }}</p>
          </v-col>

          <v-row class="mt-0 mb-1 justify-center">
            <v-col class="text-center" cols="12">
              <h4
                class="text-h5"
                v-html="$t('ra-version.home-page.text4')"
              ></h4>
            </v-col>
            <v-col cols="12" sm="4" lg="4" md="12">
              <v-btn-toggle v-model="toggle_exclusive" class="primary">
                <v-btn depressed color="primary" @click="startNow" large>
                  <font-awesome-icon
                    v-if="!isRaMicro"
                    class="mr-3"
                    :icon="['fas', 'phone-alt']"
                    :style="{ fontSize: 15 }"
                  />
                  {{ $t("profile.homeProfile.videoStart") }}
                </v-btn>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      depressed
                      class="buttonEuroMainScreen pt-2 pb-2"
                      @click="showLinkModal"
                      :loading="loading"
                      large
                    >
                      {{ $t("profile.homeProfile.invite") }}
                    </v-btn>
                  </template>
                  <span>
                    {{ getTooltipButton }}
                  </span>
                </v-tooltip>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-row>
      </div>
      <needs-login
        :showNeedsLogin="showNeedsLogin"
        :closeModal="closeNeedsLogin"
        :goToLogin="goToLogin"
        :goToRegister="goToRegister"
      />
      <StripeConnectModal
        :showStripeConnect="showStripeConnect"
        :closeModal="closeStripeConnect"
        :isStart="true"
      />
      <!-- <Footer /> -->
    </template>
    <template v-else>
      <Call />
    </template>

    <template v-if="showModalSecurity">
      <Modal2FA
        :showModalSecurity="showModalSecurity"
        :closeModalSecurity="closeModalSecurity"
        :continueToProfile="continueToProfile"
      />
    </template>
  </v-container>
</template>

<script>
import Call from "./call.vue";
// import Footer from "@/components/footer.vue";

import { getFirebaseToken, getUser } from "@/firebase";
import {
  randomString,
  availableLanguages,
  isRaMicro,
  isMobile
} from "../utils.js";
import PaylinkModal from "./modals/paylinkModal.vue";
import NeedsLogin from "./modals/needsLogin.vue";
import StripeConnectModal from "./modals/setupStripeConnect.vue";
import Modal2FA from "./modals/modal2fa.vue";
import FingerprintJS from "fingerprintjs";
import { EventBus } from "../bus";
/* eslint-disable */
export default {
  name: "StartPage",
  components: { Call, PaylinkModal, NeedsLogin, StripeConnectModal,Modal2FA }, //Footer
  data: () => ({
    userHasNotPaymentsActive: true,
    showCallComponent: false,
    toggle_exclusive: 2,
    loading: false,
    isRaMicro: isRaMicro(),
    isMobile: isMobile(),
    showNeedsLogin: false,
    showStripeConnect: false,
    loadingStripe: false,
    loadingButton: false,
    showModalSecurity: false
  }),
  watch: {
    userLoading: {
      immediate: true,
      handler: function (value) {
        if (!value && this.user) {
          this.startUp()
        }
      } 
    },
    loadingStripe: {
      immediate: true,
      handler:  function (value) {
        if ( !value && this.loadingButton){
          setTimeout(() => {
            this.loadingButton = false;
            this.startNow();
          }, 500)
        }
      } 
    },
    
  },
  created(){
    this.check2FA();
  },
  mounted() {
    if (typeof(Storage) !== "undefined") {
      if (localStorage.getItem("refts")) {
        const msPerDay = 86400000
        if (localStorage.getItem("refts") < Date.now() - (30 * msPerDay)) {
          localStorage.removeItem("refts")
          localStorage.removeItem("ref")
        }
      }

      if (this.$route.query.ref) {
        localStorage.setItem("refts", Date.now())
        localStorage.setItem("ref", this.$route.query.ref)
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/recordreferralimpression`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify({
            ref: this.$route.query.ref,
          }),
        })
      }
    }

    setTimeout(() => {
      if (this.user) {
        this.startUp()
      }
     
      EventBus.$on('showMainPage', () => {
        this.showCallComponent = false;
      })
    }, 250)
  },
  methods: {
    check2FA(){
      if ( this.user && this.user.security && !localStorage.getItem('continue_to_page')){ // add something else
         this.showModalSecurity = true;
      }
    },
    continueToProfile(value) {
      if (value) {
        this.closeModalSecurity(value);
      }
    },
    closeModalSecurity(value) {
      if (value) {
        this.showModalSecurity = false;
        if ( this.$route.path !== "/" ){
          this.$router.replace("/");
        }
      }else{
         this.$store.dispatch("USER_SIGNOUT");
         this.showModalSecurity = false;
          if (this.$route.path !== "/") {
            this.$router.replace("/");
          }
      }
    },
    startUp(){
      if ( this.user ){
        if (!this.$store.state.user.user.language) {
          let languageCode = window.navigator.language
            .substr(0, 2)
            .toLowerCase();
          let language = "en";
          availableLanguages().forEach(language => {
            if (language.code == languageCode) {
              language = language.code;
            }
          });
          this.$store.dispatch("SAVE_USER_INFO", {
            user: this.$store.state.user,
            userInfo: {
              language: language
            }
          });
        }
        if (!this.$store.state.user.user.country) {
          fetch("https://ipapi.co/json/")
            .then(response => {
              return response.json();
            })
            .then(data => {
              this.$store.dispatch("SAVE_USER_INFO", {
                user: this.$store.state.user,
                userInfo: {
                  country: data.country_name
                }
              });
            });
        }
  
        if (this.$store.state.user.user.language) {
          this.$locale.change(this.$store.state.user.user.language);
        }
  
        this.getUserHasPaymentSet();

        if (getUser().emailVerified) {
          this.$router.replace("/profile");
        }
      }
    },
    startNow(){
      if ( !this.isRaMicro ){
        this.initCall();
      }else{
        if ( this.loadingStripe ){
          this.loadingButton = true;
        }
        if ( this.user ){
          if ( !this.user.dontShowStartPage ){
            this.goToStartCallPage();
          }else{
            if ( !this.hasNotPayments ){
              this.initCall();
            }else{
              if ( !this.loadingStripe ){
                this.showStripeConnect = true;
              }
            }
          }
        }else{
          this.showNeedsLogin = true;
        }
      }
    },
    closeStripeConnect(){
      this.showStripeConnect = false;
    },
    closeNeedsLogin(){
      this.showNeedsLogin = false;
    },
    goToStartCallPage(){
      this.$router.push({ path: `/start` });
    },
    async initCall(){
      const roomId = randomString(9);
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/reserveRandomRoom`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          room: roomId
        })
      })
      .then(response => response.json())
      .then(response => { 
        if (response.success) {
          let fingerPrint = new FingerprintJS().get();
          let ts = Date.now();
          this.$router.push({ path: `/c/${roomId}?f=${fingerPrint}&ts=${ts}` });
          this.showCallComponent = true;
        }
        else {
          // Try again with a different room
          this.initCall()
        }
      })
    },
    goToLogin(){
      this.$router.push({ path: `/login` });
    },
    goToRegister(){
      this.$router.push({ path: `/register` });
    },
    async showLinkModal(){
      if ( this.user && !this.hasNotPayments ){
        this.loading = true;
        let subRoom = await this.generateRandomSubroom();
        let room = this.user.mainRoom.name + '/' + subRoom; 
        this.loading = false;
        let fingerPrint = new FingerprintJS().get();
        let ts = Date.now();
        this.$router.push({ path: `/c/${room}?f=${fingerPrint}&ts=${ts}` , query: { payLink: 'true' }});
      }
    },
    async generateRandomSubroom() {
      if (await this.ensureMainRoom()) {
        let savedRooms = [];
        let subRoomName = randomString(9); 

        if (this.$store.state.user.user.savedRooms) {
          savedRooms = this.$store.state.user.user.savedRooms.slice();
        }
        savedRooms.push({ name: subRoomName, online: true, locked: true, isPayLinkRoom: true });
        let response = await fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveSubrooms`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: this.user.mainRoom.name,
            online: this.user.mainRoom.online,
            subroom: savedRooms
          })
        })

        let responseJson = await response.json()
        if (responseJson.success) {
            this.$store.dispatch("SAVE_USER_INFO", {
              user: this.$store.state.user,
              userInfo: {
                savedRooms: savedRooms
              }
            });
            return subRoomName;
        }
      }
    },
    async ensureMainRoom() {
      if (this.user.mainRoom && this.user.mainRoom.name) {
        return true
      } else {
        // If user does not have a main room, generate a random one
        let mainRoomName = randomString(9)
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/claimRoom`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: mainRoomName
          })
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              this.$store
                .dispatch("SAVE_USER_INFO", {
                  user: this.$store.state.user,
                  userInfo: {
                    mainRoom: { name: mainRoomName, online: true }
                  }
                });
              const user = this.user
              this.$store.dispatch("USER_FETCH", { user });
              return true
            } else {
              return false
            }
          })
          .catch(error => {
            console.warn("Error on claimRoom", error);
            return false
          });
      }
    },
    async getUserHasPaymentSet(){
      this.loadingStripe = true;
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/fetchStripeConnectAccount`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            isRaMicro: this.isRaMicro,
          })
        }
      )
        .then(response => response.json())
        .then(response => {
          this.loadingStripe = false;
          if ( response.success && response.account.charges_enabled ) { // 
            this.userHasNotPaymentsActive = false;
          } else {
           this.userHasNotPaymentsActive = true;
          }
        })
        .catch(error => {
          this.loadingStripe = false;
          console.log(error);
        });
    }
  },
  computed: {
    needs2fa(){
      return this.$store.state.user.needs2fa
    },
    hasNotPayments(){
      return this.userHasNotPaymentsActive
    },
    user() {
      return this.$store.state.user.user;
    },
    userLoading() {
      return this.$store.state.user.loading
    },
    hasSettings(){
      return this.$store.state.user.user.hasSettings
    },
    getTooltipButton(){
      if ( this.user && this.hasNotPayments ){
        return this.$t('startPage.ttEuroBtnActivate');
      }else if ( this.user && !this.hasNotPayments ){
        return this.$t('startPage.ttEuroBtnGetPaid');
      }else if ( !this.user ){
        return this.$t('startPage.ttEuroBtnLogin');
      }
    }
  }
};

</script>

<style scoped lang="scss">
.progressLanding{
    height: 15px !important;
    width: 15px !important;
    margin-left: 5px !important;
}
.noWrap {
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
}
.logo{
  max-width: 350px;
  margin:0 auto;
  max-height: 66px;
}
.height-100 {
  height:100vh;
}
@media only screen and (max-width: 700px) {
.headlineTitle {
  padding-top:10px;
  font-size: 2.2rem!important;
  line-height: 3rem!important;
}
.logo{
  max-width: 250px;
  margin:0 auto;
}
.colReverse {
    display: flex !important;
    flex-flow: wrap;
    flex-direction: row-reverse;
  }
.orderXS {
    order: 13!important;
  }

}


.headlineTitle{
  max-width:550px;
  line-height: 4.5rem;
  margin:0 auto;
  font-family: Quicksand, sans-serif;
  font-weight: 400;
}
.paidSpan{
  font-family: Quicksand, sans-serif;
  font-weight: bold;
}
.subtitleMainPage{
  font-family: Quicksand, sans-serif;
  font-weight: 200;
}
.containerStartPage{
	//position: relative;
	//width: 100%;
	//max-height: 100%;
	//margin: 0 auto;
	overflow-x: hidden;
}
// .containerStartPage::after {
//     content: '';
// 		position: absolute;
// 		background: linear-gradient(to bottom, #365cc4, #009f99);
// 		padding-bottom: 56.42136%;;
//     width: 100%;
//     top: 0%;
//     right: 64%;
//     transform: rotate(40.5deg);
//     transform-origin: right top;
// }

.textContainer {
  font-family: Muli, sans-serif;
  margin: 0 auto;
  width: 30vw;
  min-width: 300px;
}
.height-30 {
  min-height:40vh;
}

.backWhite {
  background-color:white;
}

.w-100{
  width: 100%;
}
.room-selection {
  margin: 0 auto 0 auto; 
  padding: 1em 1.5em 1.3em 1.5em;
  //max-width: 700px;
  position: relative;
  top: 7em;
}
.h-divider{
    margin: auto;
    width: 100%;
    position: relative;
  .shadow {
    overflow: hidden;
    height: 0px;
    border-top: 1px solid;
  }
  
  .text{
    width: 25%;
    height: auto;
    padding: 2px;
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    left: 0;
    margin-left: 0;
    background: white;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: -13px;
    font-family: Muli, sans-serif;
  }
}
.buttonEuroMainScreen{
  background-color: #42d13f !important; //#4EF04A !important;
  color: white !important;
  opacity: 1 !important;
}
.v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default{
  height: 36px;
}
.alertSuccess {
  background-color: black;
  color: white;
  max-width: 170px;
  top: 15px;
  left: calc(100% - 170px);
  padding: 5px;
  font-size: 14px;
}
</style>
