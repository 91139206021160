import Vue from "vue";

export function randomString(strLength) {
  var result = [];
  strLength = strLength || 5;
  var charSet = "0123456789";
  while (strLength--) {
    result.push(charSet.charAt(Math.floor(Math.random() * charSet.length)));
  }
  return result.join("");
}

export function isMobile() {
  if (
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export function shareLinkOnMobile(url) {
  const shareOptions = {
    title: "easymeet.me",
    text: Vue.prototype.$t("generics.titleApp"),
    url: url
  };
  navigator.share(shareOptions);
}

export function copyLink(url) {
  var selBox2 = document.createElement("textarea");
  selBox2.style.position = "fixed";
  selBox2.style.left = "0";
  selBox2.style.top = "0";
  selBox2.style.opacity = "0";
  selBox2.value = url;
  document.body.appendChild(selBox2);
  selBox2.focus();
  selBox2.select();
  document.execCommand("copy");
  document.body.removeChild(selBox2);
}

export function availableLanguages() {
  return [
    { name: Vue.prototype.$t("languages.en"), code: "en" },
    { name: Vue.prototype.$t("languages.es"), code: "es" },
    { name: Vue.prototype.$t("languages.de"), code: "de" },
    { name: Vue.prototype.$t("languages.ru"), code: "ru" }
  ];
}

export function isRaMicro() {
  return true;
}

/*
export const ISOLanguages = [
  { name: "Abkhazian", code: "ab" },
  { name: "Afar", code: "aa" },
  { name: "Afrikaans", code: "af" },
  { name: "Akan", code: "ak" },
  { name: "Albanian", code: "sq" },
  { name: "Amharic", code: "am" },
  { name: "Arabic", code: "ar" },
  { name: "Aragonese", code: "an" },
  { name: "Armenian", code: "hy" },
  { name: "Assamese", code: "as" },
  { name: "Avaric", code: "av" },
  { name: "Avestan", code: "ae" },
  { name: "Aymara", code: "ay" },
  { name: "Azerbaijani", code: "az" },
  { name: "Bambara", code: "bm" },
  { name: "Bashkir", code: "ba" },
  { name: "Basque", code: "eu" },
  { name: "Belarusian", code: "be" },
  { name: "Bengali (Bangla)", code: "bn" },
  { name: "Bihari", code: "bh" },
  { name: "Bislama", code: "bi" },
  { name: "Bosnian", code: "bs" },
  { name: "Breton", code: "br" },
  { name: "Bulgarian", code: "bg" },
  { name: "Burmese", code: "my" },
  { name: "Catalan", code: "ca" },
  { name: "Chamorro", code: "ch" },
  { name: "Chechen", code: "ce" },
  { name: "Chichewa, Chewa, Nyanja", code: "ny" },
  { name: "Chinese", code: "zh" },
  { name: "Chinese (Simplified)", code: "zh-Hans" },
  { name: "Chinese (Traditional)", code: "zh-Hant" },
  { name: "Chuvash", code: "cv" },
  { name: "Cornish", code: "kw" },
  { name: "Corsican", code: "co" },
  { name: "Cree", code: "cr" },
  { name: "Croatian", code: "hr" },
  { name: "Czech", code: "cs" },
  { name: "Danish", code: "da" },
  { name: "Divehi, Dhivehi, Maldivian", code: "dv" },
  { name: "Dutch", code: "nl" },
  { name: "Dzongkha", code: "dz" },
  { name: "English", code: "en" },
  { name: "Esperanto", code: "eo" },
  { name: "Estonian", code: "et" },
  { name: "Ewe", code: "ee" },
  { name: "Faroese", code: "fo" },
  { name: "Fijian", code: "fj" },
  { name: "Finnish", code: "fi" },
  { name: "French", code: "fr" },
  { name: "Fula, Fulah, Pulaar, Pular", code: "ff" },
  { name: "Galician", code: "gl" },
  { name: "Gaelic (Scottish)", code: "gd" },
  { name: "Gaelic (Manx)", code: "gv" },
  { name: "Georgian", code: "ka" },
  { name: "German", code: "de" },
  { name: "Greek", code: "el" },
  { name: "Greenlandic", code: "kl" },
  { name: "Guarani", code: "gn" },
  { name: "Gujarati", code: "gu" },
  { name: "Haitian Creole", code: "ht" },
  { name: "Hausa", code: "ha" },
  { name: "Hebrew", code: "he" },
  { name: "Herero", code: "hz" },
  { name: "Hindi", code: "hi" },
  { name: "Hiri Motu", code: "ho" },
  { name: "Hungarian", code: "hu" },
  { name: "Icelandic", code: "is" },
  { name: "Ido", code: "io" },
  { name: "Igbo", code: "ig" },
  { name: "Indonesian", code: "id, in" },
  { name: "Interlingua", code: "ia" },
  { name: "Interlingue", code: "ie" },
  { name: "Inuktitut", code: "iu" },
  { name: "Inupiak", code: "ik" },
  { name: "Irish", code: "ga" },
  { name: "Italian", code: "it" },
  { name: "Japanese", code: "ja" },
  { name: "Javanese", code: "jv" },
  { name: "Kalaallisut, Greenlandic", code: "kl" },
  { name: "Kannada", code: "kn" },
  { name: "Kanuri", code: "kr" },
  { name: "Kashmiri", code: "ks" },
  { name: "Kazakh", code: "kk" },
  { name: "Khmer", code: "km" },
  { name: "Kikuyu", code: "ki" },
  { name: "Kinyarwanda (Rwanda)", code: "rw" },
  { name: "Kirundi", code: "rn" },
  { name: "Kyrgyz", code: "ky" },
  { name: "Komi", code: "kv" },
  { name: "Kongo", code: "kg" },
  { name: "Korean", code: "ko" },
  { name: "Kurdish", code: "ku" },
  { name: "Kwanyama", code: "kj" },
  { name: "Lao", code: "lo" },
  { name: "Latin", code: "la" },
  { name: "Latvian (Lettish)", code: "lv" },
  { name: "Limburgish ( Limburger)", code: "li" },
  { name: "Lingala", code: "ln" },
  { name: "Lithuanian", code: "lt" },
  { name: "Luga-Katanga", code: "lu" },
  { name: "Luganda, Ganda", code: "lg" },
  { name: "Luxembourgish", code: "lb" },
  { name: "Manx", code: "gv" },
  { name: "Macedonian", code: "mk" },
  { name: "Malagasy", code: "mg" },
  { name: "Malay", code: "ms" },
  { name: "Malayalam", code: "ml" },
  { name: "Maltese", code: "mt" },
  { name: "Maori", code: "mi" },
  { name: "Marathi", code: "mr" },
  { name: "Marshallese", code: "mh" },
  { name: "Moldavian", code: "mo" },
  { name: "Mongolian", code: "mn" },
  { name: "Nauru", code: "na" },
  { name: "Navajo", code: "nv" },
  { name: "Ndonga", code: "ng" },
  { name: "Northern Ndebele", code: "nd" },
  { name: "Nepali", code: "ne" },
  { name: "Norwegian", code: "no" },
  { name: "Norwegian bokmål", code: "nb" },
  { name: "Norwegian nynorsk", code: "nn" },
  { name: "Nuosu", code: "ii" },
  { name: "Occitan", code: "oc" },
  { name: "Ojibwe", code: "oj" },
  { name: "Old Church Slavonic, Old Bulgarian", code: "cu" },
  { name: "Oriya", code: "or" },
  { name: "Oromo (Afaan Oromo)", code: "om" },
  { name: "Ossetian", code: "os" },
  { name: "Pāli", code: "pi" },
  { name: "Pashto, Pushto", code: "ps" },
  { name: "Persian (Farsi)", code: "fa" },
  { name: "Polish", code: "pl" },
  { name: "Portuguese", code: "pt" },
  { name: "Punjabi (Eastern)", code: "pa" },
  { name: "Quechua", code: "qu" },
  { name: "Romansh", code: "rm" },
  { name: "Romanian", code: "ro" },
  { name: "Russian", code: "ru" },
  { name: "Sami", code: "se" },
  { name: "Samoan", code: "sm" },
  { name: "Sango", code: "sg" },
  { name: "Sanskrit", code: "sa" },
  { name: "Serbian", code: "sr" },
  { name: "Serbo-Croatian", code: "sh" },
  { name: "Sesotho", code: "st" },
  { name: "Setswana", code: "tn" },
  { name: "Shona", code: "sn" },
  { name: "Sichuan Yi", code: "ii" },
  { name: "Sindhi", code: "sd" },
  { name: "Sinhalese", code: "si" },
  { name: "Siswati", code: "ss" },
  { name: "Slovak", code: "sk" },
  { name: "Slovenian", code: "sl" },
  { name: "Somali", code: "so" },
  { name: "Southern Ndebele", code: "nr" },
  { name: "Spanish", code: "es" },
  { name: "Sundanese", code: "su" },
  { name: "Swahili (Kiswahili)", code: "sw" },
  { name: "Swati", code: "ss" },
  { name: "Swedish", code: "sv" },
  { name: "Tagalog", code: "tl" },
  { name: "Tahitian", code: "ty" },
  { name: "Tajik", code: "tg" },
  { name: "Tamil", code: "ta" },
  { name: "Tatar", code: "tt" },
  { name: "Telugu", code: "te" },
  { name: "Thai", code: "th" },
  { name: "Tibetan", code: "bo" },
  { name: "Tigrinya", code: "ti" },
  { name: "Tonga", code: "to" },
  { name: "Tsonga", code: "ts" },
  { name: "Turkish", code: "tr" },
  { name: "Turkmen", code: "tk" },
  { name: "Twi", code: "tw" },
  { name: "Uyghur", code: "ug" },
  { name: "Ukrainian", code: "uk" },
  { name: "Urdu", code: "ur" },
  { name: "Uzbek", code: "uz" },
  { name: "Venda", code: "ve" },
  { name: "Vietnamese", code: "vi" },
  { name: "Volapük", code: "vo" },
  { name: "Wallon", code: "wa" },
  { name: "Welsh", code: "cy" },
  { name: "Wolof", code: "wo" },
  { name: "Western Frisian", code: "fy" },
  { name: "Xhosa", code: "xh" },
  { name: "Yiddish", code: "yi, ji" },
  { name: "Yoruba", code: "yo" },
  { name: "Zhuang, Chuang", code: "za" },
  { name: "Zulu", code: "zu" }
];
*/
