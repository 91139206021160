var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"fixed":"","color":"white","elevation":0,"height":!_vm.isMobile ? '100px' : '64px'}},[(_vm.$route.path == '/profile')?_c('v-app-bar-nav-icon',{on:{"click":_vm.handlerClickBars}}):_vm._e(),[(_vm.showImageLogo && _vm.isMobile && !_vm.isRaMicro)?_c('v-img',{staticClass:"logo",attrs:{"contain":"","src":require("../assets/easymeet_icon.svg")},on:{"click":_vm.goToMainPage}}):_vm._e(),(_vm.showImageLogo && !_vm.isMobile && !_vm.isRaMicro)?_c('v-img',{staticClass:"logo",attrs:{"contain":"","src":require("../assets/easymeet_logo-b.svg")},on:{"click":_vm.goToMainPage}}):_vm._e()],[(_vm.showImageLogo && _vm.isMobile && _vm.isRaMicro)?_c('v-img',{staticClass:"logo",attrs:{"contain":"","src":require("../assets/easymeet_icon.svg")},on:{"click":_vm.goToMainPage}}):_vm._e(),(_vm.showImageLogo && !_vm.isMobile && _vm.isRaMicro)?_c('v-img',{staticClass:"logo",attrs:{"contain":"","src":require("../assets/easymeet_logo-b.svg")},on:{"click":_vm.goToMainPage}}):_vm._e()],_c('v-spacer'),(
      _vm.$route.path !== '/profile' &&
        _vm.$route.path !== '/plans' &&
        !_vm.isMobile &&
        !_vm.isRaMicro
    )?_c('router-link',{staticClass:"noWrap mt-1",class:{ 'mr-5': _vm.$route.path == '/' },attrs:{"to":"/plans"}},[_vm._v(_vm._s(_vm.$t("generics.pricing")))]):_vm._e(),(_vm.$route.path !== '/' && !_vm.isRaMicro)?_c('v-btn',{staticClass:"mx-3 call-btn",attrs:{"icon":"","outlined":"","color":"primary"},on:{"click":_vm.startNow}},[_c('font-awesome-icon',{style:({ fontSize: 16 }),attrs:{"icon":['fas', 'phone-alt']}})],1):_vm._e(),(
      !_vm.user &&
        _vm.$route.path !== '/login' &&
        _vm.$route.path !== '/register' &&
        _vm.$route.path !== '/forgot-password'
    )?_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.goToLogin}},[_vm._v(" "+_vm._s(_vm.$t("generics.login"))+" ")]):_vm._e(),(
      _vm.user &&
        _vm.$route.path !== '/login' &&
        _vm.$route.path !== '/register' &&
        _vm.$route.path !== '/forgot-password'
    )?_c('v-menu',{attrs:{"rounded":"","offset-y":"","min-width":"350"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var attrs = ref.attrs;
    var on = ref.on;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":!_vm.isMobile ? '80px' : '40px',"color":_vm.isRaMicro && !_vm.user.photoURL && !_vm.user.customPhotoURL ? 'primary' : ''},on:{"click":_vm.setDefaultAvatar}},'v-avatar',attrs,false),on),[(_vm.user.customPhotoURL)?_c('img',{attrs:{"src":_vm.user.customPhotoURL}}):(_vm.user.photoURL)?_c('img',{attrs:{"src":_vm.user.photoURL}}):(!_vm.user.photoURL && !_vm.user.customPhotoURL && !_vm.isRaMicro)?_c('RandomAvatar',{attrs:{"id":"avatarSvg"},on:{"click":_vm.setDefaultAvatar}}):(!_vm.user.photoURL && !_vm.user.customPhotoURL && _vm.isRaMicro)?_c('span',{staticClass:"white--text font30"},[_vm._v(_vm._s(_vm.getAvatar))]):_vm._e()],1)]}}],null,false,239485651)},[_c('v-list',{staticClass:"pt-7 pb-5"},[_c('v-list-item',[_c('v-list-item-title',[_c('v-avatar',{attrs:{"size":!_vm.isMobile ? '80px' : '40px',"color":_vm.isRaMicro && !_vm.user.photoURL && !_vm.user.customPhotoURL
                ? 'primary'
                : ''}},[(_vm.user.customPhotoURL)?_c('img',{attrs:{"contain":"","src":_vm.user.customPhotoURL}}):(_vm.user.photoURL)?_c('img',{attrs:{"contain":"","src":_vm.user.photoURL}}):(!_vm.user.photoURL && !_vm.user.customPhotoURL && _vm.isRaMicro)?_c('span',{staticClass:"white--text",staticStyle:{"font-size":"30px"}},[_vm._v(_vm._s(_vm.getAvatar))]):_vm._e()])],1)],1),_c('v-list-item',{staticClass:"mt-2"},[_c('v-list-item-title',[(_vm.user.displayName)?_c('p',{staticClass:"mb-0 font-weight-bold body-1"},[_vm._v(" "+_vm._s(_vm.user.displayName)+" ")]):_vm._e(),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.user.email))])])],1),_c('v-list-item',{staticClass:"mt-1 mb-2"},[_c('v-list-item-title',[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.goToManageAccount}},[_vm._v(" "+_vm._s(_vm.$t("links.manage-account"))+" ")])],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.doLogout}},[_vm._v(" "+_vm._s(_vm.$t("links.logout"))+" ")])],1)],1)],1)],1):_vm._e(),_c('needs-login',{attrs:{"showNeedsLogin":_vm.showNeedsLogin,"closeModal":_vm.closeNeedsLogin,"goToLogin":_vm.goToLogin,"goToRegister":_vm.goToRegister}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }