<template>
  <div class="call" ref="callContainer">
    <audio
      style="display: none;"
      id="successSound"
      src="../assets/media/cash_register.mp3"
    ></audio>

    <audio
      style="display: none;"
      id="errorSound"
      src="../assets/media/cancel.mp3"
    ></audio>

    <v-progress-linear
      absolute
      v-if="loadingVideoFeed || loadingAudioFeed || loadingOther"
      color="primary accent-4"
      indeterminate
      rounded
      height="5"
    ></v-progress-linear>

    <!-- Mobile Buttons -->
    <div
      v-if="isMobile"
      class="icons"
      :class="{ hidden: !showIcons, active: showIcons }"
      :style="`bottom: ${roomLinkHeight + roomLinkHeight + 10}px`"
      @mouseenter="clearTimeOutFunction"
    >
      <nav class="menu" v-if="isOwner">
        <input
          type="checkbox"
          href="#"
          class="menu-open"
          name="menu-open"
          id="menu-open-payment"
        />
        <label
          class="menu-open-button"
          for="menu-open-payment"
          @click="
            collapseMenu('menu-open-video');
            collapseMenu('menu-open-audio');
          "
        >
          <font-awesome-icon
            :icon="['far', 'euro-sign']"
            :style="{ fontSize: 15, color: '#ffffff' }"
          />
        </label>
        <a
          href="#"
          class="menu-item"
          @click="
            showRquestPayment();
            collapseAllMenu();
          "
        >
          <font-awesome-icon
            :icon="['fas', 'hand-holding-usd']"
            :style="{ fontSize: 15, color: '#ffffff' }"
          />
        </a>
      </nav>

      <div v-if="!mobileAudioHeadset && !mobileAudioBluetooth" class="menu">
        <label
          class="menu-open-button"
          icon
          @click="
            toggleAudio();
            collapseAllMenu();
          "
        >
          <font-awesome-icon
            v-if="localAudioTrack"
            :icon="['fas', 'microphone']"
            :style="{
              fontSize: 15,
              color: isRaMicro ? 'red' : '#ffffff'
            }"
          />
          <!-- <v-img
            src="../assets/icons-call/microphone-slash-red-white.svg"
            max-height="15"
            max-width="15"
            contain
            v-else
            class="redBlinking"
          ></v-img> -->
          <font-awesome-icon
            v-else
            :icon="['fas', 'microphone-slash']"
            class="redBlinking"
            :style="{
              fontSize: 15
            }"
          />
        </label>
      </div>
      <nav class="menu" v-if="mobileAudioHeadset || mobileAudioBluetooth">
        <input
          type="checkbox"
          href="#"
          class="menu-open"
          name="menu-open"
          id="menu-open-audio"
        />
        <label
          class="menu-open-button"
          for="menu-open-audio"
          @click="
            collapseMenu('menu-open-payment');
            collapseMenu('menu-open-video');
          "
        >
          <font-awesome-icon
            v-if="localAudioTrack"
            :icon="['fas', 'microphone']"
            :style="{ fontSize: 15, color: isRaMicro ? 'red' : '#ffffff' }"
          />
          <!-- <v-img
            src="../assets/icons-call/microphone-slash-red-white.svg"
            max-height="15"
            max-width="15"
            contain
            v-else
            class="redBlinking"
          ></v-img> -->
          <font-awesome-icon
            v-else
            :icon="['fas', 'microphone-slash']"
            class="redBlinking"
            :style="{ fontSize: 15 }"
          />
        </label>
        <a
          href="#"
          class="menu-item"
          @click="
            switchToMobileAudio('mic');
            collapseAllMenu();
          "
        >
          <font-awesome-icon
            :icon="['fas', 'microphone']"
            :style="{ fontSize: 15, color: isRaMicro ? 'red' : '#ffffff' }"
          />
        </a>
        <a
          v-if="mobileAudioHeadset"
          href="#"
          class="menu-item"
          @click="
            switchToMobileAudio('headset');
            collapseAllMenu();
          "
        >
          <font-awesome-icon
            :icon="['fas', 'headset']"
            :style="{ fontSize: 15, color: '#ffffff' }"
          />
        </a>
        <a
          v-if="mobileAudioBluetooth"
          href="#"
          class="menu-item"
          @click="
            switchToMobileAudio('bluetooth');
            collapseAllMenu();
          "
        >
          <font-awesome-icon
            :icon="['fab', 'bluetooth-b']"
            :style="{ fontSize: 15, color: '#ffffff' }"
          />
        </a>

        <a
          href="#"
          class="menu-item"
          @click="
            disableAudio();
            collapseAllMenu();
          "
        >
          <font-awesome-icon
            :icon="['fas', 'microphone-slash']"
            class="redBlinking"
            :style="{ fontSize: 15 }"
          />
          <!-- <v-img
            src="../assets/icons-call/microphone-slash-red-white.svg"
            max-height="15"
            max-width="15"
            contain
            class="redBlinking"
          ></v-img> -->
        </a>
      </nav>

      <nav class="menu">
        <input
          type="checkbox"
          href="#"
          class="menu-open"
          name="menu-open"
          id="menu-open-video"
        />
        <label
          class="menu-open-button"
          for="menu-open-video"
          @click="
            collapseMenu('menu-open-payment');
            collapseMenu('menu-open-audio');
          "
        >
          <font-awesome-icon
            v-if="localVideoTrack"
            :icon="['fas', 'video']"
            :style="{ fontSize: 15, color: '#ffffff' }"
          />
          <font-awesome-icon
            v-else
            :icon="['fas', 'video-slash']"
            :style="{ fontSize: 15, color: '#ffffff' }"
          />
        </label>
        <a
          href="#"
          class="menu-item"
          @click="
            switchToFront();
            collapseAllMenu();
          "
        >
          <font-awesome-icon
            :icon="['fas', 'mobile-alt']"
            :style="{
              fontSize: 15,
              color: '#ffffff',
              transform: 'scale(1, -1)'
            }"
          />
        </a>
        <a
          href="#"
          class="menu-item"
          @click="
            switchToBack();
            collapseAllMenu();
          "
        >
          <font-awesome-icon
            :icon="['fas', 'mobile']"
            :style="{
              fontSize: 15,
              color: '#ffffff',
              transform: 'scale(1, -1)'
            }"
          />
        </a>

        <a
          href="#"
          class="menu-item"
          @click="
            disableVideo();
            collapseAllMenu();
          "
        >
          <font-awesome-icon
            :icon="['fas', 'video-slash']"
            :style="{ fontSize: 15, color: '#ffffff' }"
          />
        </a>
      </nav>

      <v-tooltip right v-if="isOwner">
        <template v-slot:activator="{ on }">
          <v-btn class="buttonsCall" icon @click="showPinModal" v-on="on">
            <font-awesome-icon
              v-if="!roomProtected"
              :icon="['far', 'user-unlock']"
              :style="{ fontSize: 17, color: '#ffffff' }"
            />

            <font-awesome-icon
              v-else
              :icon="['far', 'user-lock']"
              :style="{ fontSize: 17, color: '#7dcd7d' }"
            />
          </v-btn>
        </template>
        <span>Protect with pin</span>
      </v-tooltip>

      <div class="menu">
        <label
          class="menu-open-button"
          icon
          @click="
            toggleHangUp();
            collapseAllMenu();
          "
        >
          <font-awesome-icon
            :icon="['fas', 'phone']"
            :style="{
              fontSize: 15,
              color: '#ffffff',
              transform: 'rotate(226deg)'
            }"
          />
        </label>
      </div>
    </div>

    <!-- Non Mobile Buttons -->
    <div class="watermark">
      <v-img
        src="../assets/easymeet_icon.svg"
        max-height="35"
        max-width="35"
        contain
      ></v-img>
    </div>
    <div
      v-if="!isMobile"
      class="icons blurVideo"
      :class="{ hidden: !showIcons, active: showIcons }"
      :style="`bottom: ${roomLinkHeight}px`"
      @mouseenter="clearTimeOutFunction"
    >
      <div class="pRelative">
        <v-tooltip right v-if="isOwner">
          <template v-slot:activator="{ on }">
            <v-btn
              class="buttonsCall"
              icon
              @click="showRquestPayment"
              v-on="on"
            >
              <font-awesome-icon
                :icon="['far', 'euro-sign']"
                :style="{ fontSize: 17, color: '#ffffff' }"
              />
            </v-btn>
          </template>
          <span v-if="user"><b>easymeet</b> facepay</span>
          <span v-else>{{ $t("callComponent.registerToRequestMoney") }}</span>
        </v-tooltip>
      </div>

      <div class="pRelative">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="buttonsCall"
              icon
              @click="toggleAudio"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon
                v-if="localAudioTrack"
                :icon="['fas', 'microphone']"
                :style="{ fontSize: 15, color: isRaMicro ? 'red' : '#ffffff' }"
              />
              <!-- <v-img
                src="../assets/icons-call/microphone-slash-red-white.svg"
                max-height="15"
                max-width="15"
                contain
                v-else
                class="redBlinking"
              ></v-img> -->
              <font-awesome-icon
                v-else
                :icon="['fas', 'microphone-slash']"
                class="redBlinking"
                :style="{ fontSize: 15 }"
              />
            </v-btn>
          </template>
          <span>{{ $t("callComponent.microphone") }}</span>
        </v-tooltip>
        <v-menu
          class="customMenu"
          right
          :offset-x="true"
          v-if="Object.keys(audioDevices).length > 1"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="buttonMore" icon v-bind="attrs" v-on="on">
              <font-awesome-icon
                :icon="['fas', 'chevron-down']"
                :style="{ fontSize: 13, color: '#ffffff' }"
              />
            </v-btn>
          </template>
          <v-list color="rgba(0, 0, 0, 0.6)">
            <v-list-item class="text-left font-weight-medium white--text">
              <v-list-item-title>Speakers</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="switchAudio(item.deviceId)"
              class="text-left cursorPointer white--text"
              v-for="(item, index) in audioDevices"
              :key="index"
            >
              <v-list-item-title
                :class="{
                  'font-weight-bold': audioDeviceSelected === item.deviceId
                }"
                >{{ item.label }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="pRelative">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="buttonsCall"
              icon
              @click="toggleVideo"
              v-bind="attrs"
              v-on="on"
            >
              <font-awesome-icon
                v-if="localVideoTrack"
                :icon="['fas', 'video']"
                :style="{ fontSize: 15, color: '#ffffff' }"
              />
              <font-awesome-icon
                v-else
                :icon="['fas', 'video-slash']"
                :style="{ fontSize: 15, color: '#ffffff' }"
              />
            </v-btn>
          </template>
          <span>{{ $t("callComponent.camera") }}</span>
        </v-tooltip>
        <v-menu
          class="customMenu"
          right
          :offset-x="true"
          v-if="Object.keys(videoDevices).length > 1"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="buttonMore" icon v-bind="attrs" v-on="on">
              <font-awesome-icon
                :icon="['fas', 'chevron-down']"
                :style="{ fontSize: 13, color: '#ffffff' }"
              />
            </v-btn>
          </template>
          <v-list color="rgba(0, 0, 0, 0.6)">
            <v-list-item class="text-left font-weight-medium white--text">
              <v-list-item-title
                >{{ $t("callComponent.camera") }}:</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              class="text-left cursorPointer white--text"
              v-for="(item, index) in videoDevices"
              :key="index"
              @click="switchVideo(item.deviceId)"
            >
              <v-list-item-title
                :class="{
                  'font-weight-bold': videoDeviceSelected === item.deviceId
                }"
                >{{ item.label }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="buttonsCall"
            icon
            @click="toggleScreenShare"
            v-bind="attrs"
            v-on="on"
            :disabled="
              participantSharing &&
                room &&
                participantSharing !== room.myUserId()
            "
          >
            <font-awesome-icon
              :icon="['fas', 'desktop']"
              :style="{ fontSize: 15, color: '#ffffff' }"
            />
            <font-awesome-icon
              :icon="['fas', 'slash']"
              :style="{
                color: 'white',
                fontSize: 17,
                position: 'absolute',
                top: -3,
                left: 7
              }"
              v-if="desktopSharingActive"
            />
          </v-btn>
        </template>
        <span v-if="desktopSharingActive">{{
          $t("callComponent.stopScreenshare")
        }}</span>
        <span v-else>{{ $t("callComponent.screenshare") }}</span>
      </v-tooltip>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="buttonsCall"
            icon
            @click="toggleFullScreen"
            v-bind="attrs"
            v-on="on"
          >
            <font-awesome-icon
              :icon="['fas', 'expand-wide']"
              :style="{ fontSize: 15, color: '#ffffff' }"
            />
          </v-btn>
        </template>
        <span>{{ $t("callComponent.fullscreen") }}</span>
      </v-tooltip>

      <div class="pRelative">
        <v-tooltip right v-if="isOwner">
          <template v-slot:activator="{ on }">
            <v-btn
              class="buttonsCall"
              icon
              @click="handlerSetPassword"
              v-on="on"
            >
              <font-awesome-icon
                v-if="!roomProtected"
                :icon="['far', 'user-unlock']"
                :style="{ fontSize: 17, color: '#ffffff' }"
              />

              <font-awesome-icon
                v-else
                :icon="['far', 'user-lock']"
                :style="{ fontSize: 17, color: '#7dcd7d' }"
              />
            </v-btn>
          </template>
          <span>{{
            !roomProtected
              ? $t("callComponent.protectRoom")
              : $t("callComponent.roomProtected")
          }}</span>
        </v-tooltip>
      </div>

      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="buttonsCall"
            icon
            @click="toggleHangUp()"
            v-bind="attrs"
            v-on="on"
          >
            <font-awesome-icon
              :icon="['fas', 'phone']"
              class="rotatedIcon"
              :style="{ fontSize: 15, color: '#ffffff' }"
            />
          </v-btn>
        </template>
        <span>{{ $t("callComponent.hangUp") }}</span>
      </v-tooltip>
    </div>

    <div v-if="!isMobile" id="blurHoverBox" class="blurVideo"></div>

    <div id="videos" @mousemove="showButtons">
      <div class="remoteVideoParent" :class="{ active: localVideoMini }">
        <div class="panel_iframe" v-if="participantDisplayName">
          <ul id="button_list" class="ul_icons_content">
            <li class="li_icons_content">
              {{ participantDisplayName }}
            </li>
          </ul>
        </div>
        <div
          class="noVideoParent"
          v-if="!remoteHasVideo && isOtherUserAvailable"
        >
          <div class="noVideo">
            <v-avatar size="60px" color="primary">
              <span class="white--text" v-if="participantDisplayName">{{
                getAvatar(participantDisplayName)
              }}</span>
            </v-avatar>
          </div>
        </div>
        <video
          ref="remoteVideo"
          :class="
            `remoteVideo ${localVideoMini ? 'active' : ''} ${
              desktopSharingActive &&
              room &&
              participantSharing !== room.myUserId()
                ? 'noContentFit'
                : ''
            }`
          "
          autoplay
          playsinline
          muted
        ></video>
        <audio ref="remoteAudio" class="hidden" controls autoplay></audio>
      </div>
      <div class="parentLocalVideo" :class="{ mini: localVideoMini }">
        <div class="panel_iframe panelLocal" v-if="getLocalDisplayName">
          <ul id="button_list" class="ul_icons_content">
            <li class="li_icons_content">
              {{ getLocalDisplayName }}
            </li>
          </ul>
        </div>

        <div class="noVideoParent" v-if="!localVideoTrack">
          <div class="noVideo">
            <img
              v-if="user && user.customPhotoURL"
              :src="user.customPhotoURL"
            />
            <img v-else-if="user && user.photoURL" :src="user.photoURL" />
            <v-avatar size="40px" color="primary" v-else>
              <span class="white--text">{{
                getAvatar(getLocalDisplayName)
              }}</span>
            </v-avatar>
          </div>
        </div>

        <video
          ref="localVideo"
          :class="
            `localVideo ${localVideoMini ? 'mini' : ''} ${
              desktopSharingActive ? 'noContentFit' : ''
            }`
          "
          autoplay
          playsinline
          muted
        ></video>
        <audio ref="localAudio" class="hidden" controls autoplay muted></audio>
      </div>
    </div>
    <div
      ref="divRoomLink"
      :style="`bottom: ${isMobile ? roomLinkHeight : 0}px`"
      :class="
        `divRoomLink ${showShareLink && !showAlertOcupied ? 'active' : ''}`
      "
    >
      <!-- <v-img
        v-if="isRaMicro"
        class="logoCall"
        contain
        width="30"
        height="30"
        src="../assets/ra-easymeet_icon.svg"
      ></v-img> -->

      <span v-if="isOwner">{{ $t("callComponent.shareText") }}</span>
      <span v-else>{{ $t("callComponent.shareText").split(":")[0] }}</span>

      <div class="regularBar" v-if="isOwner">
        <a
          v-if="!isMobile"
          id="room-link-href"
          :href="getRoomLink + fingerPrintString()"
          target="_blank"
          v-html="$t('callComponent.yourEasyMeetRoom')"
        ></a>
        <div class="pullRight">
          <v-btn
            class="buttonsCall"
            icon
            v-if="!isMobile"
            @click="copyLinkClick"
          >
            <font-awesome-icon
              :icon="['far', 'copy']"
              :style="{ fontSize: 18, color: '#ffffff' }"
            />
          </v-btn>

          <v-btn
            class="buttonsCall"
            icon
            v-else
            @click="shareLinkOnMobile(getRoomLink + fingerPrintString())"
          >
            <font-awesome-icon
              :icon="['far', 'share']"
              :style="{ fontSize: 18, color: '#ffffff' }"
            />
          </v-btn>
          <v-btn
            v-if="user"
            class="buttonsCall mx-0"
            icon
            target="_blank"
            :href="
              `mailto:?subject=${user.displayName ||
                user.email}%20from%20easymeet.me&body=${this.getRoomLink +
                this.fingerPrintString().replace('&', '%26')},`
            "
          >
            <font-awesome-icon
              :icon="['far', 'envelope']"
              :style="{ fontSize: 18, color: '#ffffff' }"
            />
          </v-btn>

          <!-- <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn class="buttonsCall mx-0" icon v-on="on">
                <font-awesome-icon
                  :icon="['far', 'sms']"
                  :style="{ fontSize: 21, color: '#ffffff' }"
                />
              </v-btn>
            </template>
            <span>{{ $t("generics.soon") }}</span>
          </v-tooltip> -->
        </div>
      </div>
    </div>
    <!-- <div v-if="participantDisplayName" id="userInfoFloatingBox">
      {{ participantDisplayName }}
    </div> -->
    <v-alert
      v-if="showAlert"
      border="left"
      class="alertSuccess"
      colored-border
      dense
      color="green accent-4"
      elevation="2"
    >
      {{ $t("callComponent.copied") }}
    </v-alert>
    <v-alert
      v-if="showAlertOcupied"
      border="left"
      class="alertSuccess"
      colored-border
      dense
      color="red accent-4"
      elevation="2"
    >
      {{ $t("callComponent.roomOcupied") }}
    </v-alert>
    <v-alert
      v-if="showAlertNotOwner"
      border="left"
      class="alertSuccess"
      colored-border
      dense
      color="red accent-4"
      elevation="2"
    >
      {{ $t("callComponent.roomNotOwner") }}
    </v-alert>

    <v-alert
      v-if="!localAudioTrack"
      border="left"
      class="infoAlert"
      colored-border
      :style="`bottom: ${roomLinkHeight + 10}px`"
      dense
      color="yellow accent-4"
      elevation="2"
    >
      {{ $t("callComponent.microphoneMuted") }}
    </v-alert>

    <template v-if="showModalSettings">
      <SettingsModal
        :showModalSettings="showModalSettings"
        :closeModal="closePaymentSettings"
      />
    </template>
    <template v-if="showRequestPaymentModal">
      <RequestPaymentModal
        :showRequestPaymentModal="showRequestPaymentModal"
        :closeModal="closeModalRequestPayment"
        @client-secret-updated="requestPayment"
        :isOtherUserAvailable="isOtherUserAvailable"
      />
    </template>
    <template v-if="showRequestedPayment">
      <RequestedPaymentModal
        :showRequestedPayment="showRequestedPayment"
        :closeModal="closeModalRequestedPayment"
        :cancelFunction="cancelFunction"
        @payment-success="successFunction"
      />
    </template>
    <template v-if="showPaymentInProgress">
      <PaymentInProgress :showPaymentInProgress="showPaymentInProgress" />
    </template>
    <template v-if="showPaymentCanceled">
      <PaymentCanceledModal
        :showPaymentCanceled="showPaymentCanceled"
        :closeModal="closePaymentCanceledModal"
      />
    </template>
    <template v-if="showPaymentSuccess">
      <PaymentSuccessModal
        :showPaymentSuccess="showPaymentSuccess"
        :closeModal="closePaymentSuccessModal"
      />
    </template>
    <template v-if="showStripeConnect">
      <StripeConnectModal
        :showStripeConnect="showStripeConnect"
        :closeModal="closeStripeConnect"
        attach=".call"
        @loading="setProgressBar"
      />
    </template>
    <!-- <template v-if="showCallEnded">
      <CallEndedModal
        :showCallEnded="showCallEnded"
        :closeModal="closeCallEndedModal"
      />
    </template> -->
    <template v-if="showPayLinkModal">
      <paylink-modal
        :showPayLinkModal="showPayLinkModal"
        :closePayLinkModal="closePayLinkModal"
        :handleShowPaymentLinkInProgress="handleShowPaymentLinkInProgress"
        :contactPayLink="contactPayLink"
      ></paylink-modal>
    </template>
    <template v-if="showPaymentLinkInProgress">
      <PaymentLinkInProgress
        :showPaymentLinkInProgress="showPaymentLinkInProgress"
        :link="link"
        :paymentIntentId="paymentIntentId"
        :closeModalPaymentLinkInProgress="closeModalPaymentLinkInProgress"
      />
    </template>
    <template v-if="pinModal">
      <SetPinModal
        :pinModal="pinModal"
        :closePinModal="closePinModal"
        :setPin="setPin"
        :roomProtected="roomProtected"
        :unlockRoom="unlockRoom"
      />
    </template>

    <template v-if="showEnterPin">
      <EnterPinModal
        :showEnterPin="showEnterPin"
        :closeShowEnterPin="closeShowEnterPin"
        :unlockConf="unlockConf"
      />
    </template>

    <template v-if="showUserLeftModal">
      <UserLeftModal
        :showUserLeftModal="showUserLeftModal"
        :closeUserLeftModal="closeUserLeftModal"
      />
    </template>

    <template v-if="showModeratorHangsUp">
      <ModeratorHangsUp
        :showModeratorHangsUp="showModeratorHangsUp"
        :closeModeratorHangsUp="closeModeratorHangsUp"
      />
    </template>

    <template v-if="showOwnerNotified">
      <OwnerNotified
        :showOwnerNotified="showOwnerNotified"
        :closeOwnerNotified="closeOwnerNotified"
      />
    </template>
    <template v-if="showRoomOccupied">
      <RoomOccupied
        :showRoomOccupied="showRoomOccupied"
        :closeRoomOccupied="closeRoomOccupied"
      />
    </template>
    <!--  -->
    <template v-if="showRegisterForPayments">
      <RegisterForPayments
        :showRegisterForPayments="showRegisterForPayments"
        :closeModalRegisterForPayments="closeModalRegisterForPayments"
      />
    </template>
  </div>
</template>

<script>
// @ is an alias to /src
import SettingsModal from "./modals/settingsModal.vue";
import RequestPaymentModal from "./modals/requestPayment.vue";
import RequestedPaymentModal from "./modals/requestedPayment.vue";
import PaymentCanceledModal from "./modals/paymentCanceled.vue";
import PaymentInProgress from "./modals/paymentInProgress.vue";
import PaymentSuccessModal from "./modals/paymentSuccess.vue";
import StripeConnectModal from "./modals/setupStripeConnect.vue";
import SetPinModal from "./modals/setPinModal.vue";
import EnterPinModal from "./modals/enterPinModal.vue";
import UserLeftModal from "./modals/userLeftModal.vue";
import ModeratorHangsUp from "./modals/moderatorHangsUp.vue";
import OwnerNotified from "./modals/ownerNotified.vue";
import RoomOccupied from "./modals/roomOccupiedAlert.vue";
import RegisterForPayments from "./modals/registerForPayments.vue";
// import CallEndedModal from "./modals/callEndedModal.vue";
import PaylinkModal from "./modals/paylinkModal.vue";
import PaymentLinkInProgress from "./modals/paymentLinkInProgress.vue";
import JitsiMeetJS from "@devcups/lib-jitsi-meet";
import {
  getFirebaseToken,
  getDeviceToken,
  fetchRoomStatus,
  getPushNotificationDevice
} from "@/firebase";
import FingerprintJS from "fingerprintjs";
import $ from "jquery"; // this is for jitsi internals only
import {
  isMobile,
  shareLinkOnMobile,
  copyLink,
  isRaMicro,
  randomString
} from "../utils";
import { EventBus } from "../bus";
export default {
  name: "call",
  components: {
    SettingsModal,
    RequestPaymentModal,
    PaymentInProgress,
    RequestedPaymentModal,
    PaymentCanceledModal,
    PaymentSuccessModal,
    StripeConnectModal,
    // CallEndedModal,
    PaylinkModal,
    PaymentLinkInProgress,
    SetPinModal,
    EnterPinModal,
    UserLeftModal,
    ModeratorHangsUp,
    OwnerNotified,
    RoomOccupied,
    RegisterForPayments
  },
  data: () => ({
    showIcons: true,
    hideIconsAfterTimeout: null,
    videoOn: true,
    microphoneOn: true,
    showShareLink: true,
    isMobile: isMobile(),
    isModerator: false,
    shareLinkOnMobile: shareLinkOnMobile,
    copyLink: copyLink,
    showAlert: false,
    showAlertNotOwner: false,
    localVideoMini: false,
    audioDevices: {},
    videoDevices: {},
    audioDeviceSelected: localStorage.getItem("audioDeviceId"),
    videoDeviceSelected: localStorage.getItem("videoDeviceId"),
    interruptedTimeout: undefined,
    localAudioTrack: null,
    localVideoTrack: null,
    desktopSharingActive: false,
    returnToCamera: false,
    showModalSettings: false,
    showRequestPaymentModal: false,
    showPaymentInProgress: false,
    room: null,
    showAlertOcupied: false,
    fullScreen: false,
    roomLinkHeight: undefined,
    mobileAudioBluetooth: false,
    mobileAudioHeadset: false,
    // showCallEnded: false,
    showRequestedPayment: false,
    showPaymentCanceled: false,
    showPaymentSuccess: false,
    loadingAudioFeed: false,
    loadingVideoFeed: false,
    loadingOther: false,
    participantDisplayName: "",
    showStripeConnect: false,
    showPayLinkModal: false,
    showPaymentLinkInProgress: false,
    link: "",
    paymentIntentId: "",
    contactPayLink: false,
    isRaMicro: isRaMicro(),
    isOtherUserAvailable: false,
    roomName: "",
    callUUID: randomString(10),
    hasPayments: false,
    isPayLinkCall: false,
    participantSharing: false,
    token: null,
    isRoomOwner: false,
    isHereFirst: false,
    pinModal: false,
    roomProtected: false,
    showEnterPin: false,
    showUserLeftModal: false,
    showModeratorHangsUp: false,
    isFingerprintOwner: false,
    showOwnerNotified: false,
    showRoomOccupied: false,
    baseUrl: window.location.origin,
    remoteHasVideo: false,
    showRegisterForPayments: false,
    handlingFocus: false
  }),
  created() {
    window.addEventListener("resize", this.windowResize);
    document.addEventListener("visibilitychange", this.onChangeVisibility);
  },
  mounted() {
    if (!this.isEtRoom) {
      let fingerPrint = new FingerprintJS().get();
      if (this.$route.query.f) {
        if (this.$route.query.f == fingerPrint) {
          this.isFingerprintOwner = true;
        } else {
          this.isFingerprintOwner = false;
        }
      }
    }

    if (this.user) {
      this.getUserHasPayments();
    }

    navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflow = "hidden";

    window.$ = $;
    console.log("Param room id", this.$route.params.roomid);
    console.log("Param subroom id", this.$route.params.subRoom);

    if (this.$refs && this.$refs.divRoomLink) {
      this.roomLinkHeight = this.$refs.divRoomLink.clientHeight;
    }
    this.setIconTimeout();

    const initOptions = {
      disableAudioLevels: true,
      disableSimulcast: true,
      disableThirdPartyRequests: true
    };
    console.log(JitsiMeetJS.version);
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
    JitsiMeetJS.init(initOptions);
    JitsiMeetJS.mediaDevices.enumerateDevices(this.updateDevices);
    JitsiMeetJS.mediaDevices.addEventListener(
      JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
      this.updateDevices
    );
    this.createVideoTrack({});
    // this.createAudioTrack(); #197 on all call starts - microphone deactivated

    const jvbOptions = {
      hosts: {
        domain: "turn.easymeet.me",
        muc: "conference.turn.easymeet.me"
      },
      serviceUrl: "wss://turn.easymeet.me/xmpp-websocket",
      clientNode: "http://turn.easymeet.me/"
    };
    const connection = new JitsiMeetJS.JitsiConnection(null, null, jvbOptions);
    this.connection = connection;
    connection.connect();
    connection.addEventListener(
      JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
      this.onConnectionSuccess
    );
    if (this.$route.query.payLink) {
      let query = Object.assign({}, this.$route.query);
      delete query.payLink;
      this.$router.replace({ query });
      this.isPayLinkCall = true;
      this.showPayLinkModal = true;
    }
    if (this.$route.query.contact) {
      let query = Object.assign({}, this.$route.query);
      this.contactPayLink = decodeURIComponent(query.contact);
      console.log("contactPayLink", this.contactPayLink);
      delete query.contact;
      this.$router.replace({ query });
    }
    if (this.isRaMicro) {
      document.addEventListener("keydown", event => {
        if (event.key === "Escape") {
          this.toggleHangUp();
        }
      });
    }
    if (this.user && this.user.displayName) {
      sessionStorage.setItem("displayName", this.user.displayName);
    }
    getDeviceToken().then(token => {
      this.token = token;
    });
    if (this.user) {
      fetchRoomStatus(
        this.$route.params.roomid,
        this.$route.params.subRoom
      ).then(roomStatus => {
        if (this.user.uid === roomStatus.owner) {
          this.isRoomOwner = true;
        }
      });
    }

    // const notifyUser = sessionStorage.getItem("showOwnerNotified");
    // console.log("<<< notifyUser >>>", notifyUser);
    // if (notifyUser) {
    //   this.showOwnerNotified = true;
    //   // sessionStorage.removeItem('showOwnerNotified')
    // }
  },
  watch: {
    hasPayments: {
      immediate: true,
      handler: function(val) {
        if (
          val &&
          !this.isOtherUserAvailable &&
          !this.showPayLinkModal &&
          this.isPayLinkCall
        ) {
          this.showPayLinkModal = true;
        }
      }
    },
    isOtherUserAvailable: {
      immediate: true,
      handler: function(val) {
        console.log("this.isPayLinkCall", this.isPayLinkCall, val);
        if (
          !val &&
          this.hasPayments &&
          !this.showPayLinkModal &&
          this.isPayLinkCall
        ) {
          this.showPayLinkModal = true;
        }
      }
    }
  },
  methods: {
    onChangeVisibility() {
      const state = document.visibilityState;
      console.log("visibilityState >>>> ", state);
      if (state == "visible" && this.isMobile && !this.handlingFocus) {
        this.handlingFocus = true;
        // if (this.localVideoTrack) {
        //   this.toggleVideo();
        //   this.toggleVideo();
        // }

        // if (this.localAudioTrack) {
        //   this.toggleAudio();
        //   this.toggleAudio();
        // }

        setTimeout(() => {
          this.handlingFocus = false;
        }, 2000);
      }
    },
    getAvatar(name) {
      if (name) {
        let avatarN = "";
        let stringToLoop = name;
        for (var i = 0; i < stringToLoop.length; i++) {
          if (
            avatarN.length < 2 &&
            /^[a-z0-9]+$/i.test(stringToLoop.charAt(i))
          ) {
            avatarN += stringToLoop.charAt(i);
          }
        }
        return avatarN.toUpperCase();
      } else {
        return "";
      }
    },
    windowResize() {
      if (this.$refs && this.$refs.divRoomLink) {
        this.roomLinkHeight = this.$refs.divRoomLink.clientHeight;
      }
    },
    closeRoomOccupied() {
      this.showRoomOccupied = false;
      this.$router.replace("/");
    },
    closeOwnerNotified() {
      this.showOwnerNotified = false;
      sessionStorage.removeItem("showOwnerNotified");
    },
    closeModeratorHangsUp() {
      this.showModeratorHangsUp = false;
      if (this.$router.currentRoute.path !== "/") {
        this.$router.replace("/");
      } else {
        EventBus.$emit("showMainPage");
      }
    },
    closeUserLeftModal() {
      this.showUserLeftModal = false;
    },
    unlockRoom() {
      if (this.room) {
        this.room.unlock();
        this.roomProtected = false;
      }
      this.closePinModal();
    },
    handlerSetPassword() {
      this.showPinModal();
    },
    closeShowEnterPin() {
      this.showEnterPin = false;
    },
    unlockConf(pin) {
      if (this.room) {
        this.room.join(pin);
        this.closeShowEnterPin();
      }
    },
    setPin(pin) {
      if (this.room) {
        this.room.lock(pin);
        this.roomProtected = true;
      }
      this.closePinModal();
    },
    closePinModal() {
      this.pinModal = false;
    },
    showPinModal() {
      this.pinModal = true;
    },
    async getUserHasPayments() {
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/fetchStripeConnectAccount`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            isRaMicro: this.isRaMicro
          })
        }
      )
        .then(response => response.json())
        .then(response => {
          if (response.success && response.account.charges_enabled) {
            //
            this.hasPayments = true;
          } else {
            this.hasPayments = false;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    closePayLinkModal() {
      this.showPayLinkModal = false;
    },
    // closeCallEndedModal() {
    //   this.showCallEnded = false;
    //   this.$router.replace("/");
    // },
    successFunction(paymentIntent, price) {
      for (const participant of this.room.getParticipants()) {
        this.sendMessage(participant.getId(), {
          type: "paymentSuccess",
          message: paymentIntent,
          price: price
        });
      }
      this.showRequestedPayment = false;
      this.showPaymentSuccess = true;
    },
    cancelFunction() {
      for (const participant of this.room.getParticipants()) {
        this.sendMessage(participant.getId(), {
          type: "paymentCanceled",
          message: "payment canceled"
        });
      }
      this.showRequestedPayment = false;
    },
    requestPayment(paymentInfo) {
      for (const participant of this.room.getParticipants()) {
        this.sendMessage(participant.getId(), {
          type: "paymentRequest",
          message: paymentInfo
        });
      }
      this.showRequestPaymentModal = false;
      this.showPaymentInProgress = true;
    },
    handleShowPaymentLinkInProgress(link, paymentIntentId) {
      this.link = link;
      this.paymentIntentId = paymentIntentId;
      this.showPaymentLinkInProgress = true;
    },
    closeModalPaymentLinkInProgress() {
      this.showPaymentLinkInProgress = false;
    },
    onConnectionSuccess() {
      console.log("xmpp connected");
      const confOptions = {
        p2p: {
          enabled: true
        }
      };
      this.roomName = this.$route.params.roomid;
      let roomName = "sclip" + this.$route.params.roomid;
      if (this.$route.params.subRoom) {
        roomName = roomName + "_" + this.$route.params.subRoom;
        this.roomName = this.roomName + "_" + this.$route.params.subRoom;
      }
      const room = this.connection.initJitsiConference(roomName, confOptions);

      room.on(
        JitsiMeetJS.events.conference.PARTICIPANT_PROPERTY_CHANGED,
        (sender, key, old, value) => {
          old = null;
          if (key == this.room.myUserId()) {
            this.onMessageRecieved(sender.getId(), JSON.parse(value).message);
          }
        }
      );
      room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);
      room.on(
        JitsiMeetJS.events.conference.CONFERENCE_JOINED,
        this.onConferenceJoined
      );
      room.on(JitsiMeetJS.events.conference.KICKED, this.onKicked);
      room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined);
      room.on(
        JitsiMeetJS.events.conference.TRACK_REMOVED,
        this.onRemoteTrackRemove
      );
      room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrackAdd);
      room.on(
        JitsiMeetJS.events.conference.USER_ROLE_CHANGED,
        this.onUserRoleChanged
      );
      room.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, err => {
        if (err == JitsiMeetJS.errors.conference.PASSWORD_REQUIRED) {
          this.showEnterPin = true;
          // this.showAlertOcupied = true;
          // console.log("Room occupied");
        }
      });
      room.on(
        JitsiMeetJS.events.conference.PARTICIPANT_CONN_STATUS_CHANGED,
        this.onParticipantConnStatusChanged
      );
      this.room = room;

      room.join();
    },
    sendMessage(participant, message) {
      this.room.setLocalParticipantProperty(
        participant,
        JSON.stringify({
          id: Math.random(),
          message: message
        })
      );
    },
    async onMessageRecieved(sender, message) {
      console.log("sender", sender, "message", message);
      if (message.type == "paymentRequest") {
        this.showRequestedPayment = message.message;
      } else if (message.type == "paymentCanceled") {
        this.showPaymentInProgress = false;
        this.showPaymentCanceled = true;
        if (document.getElementById("errorSound")) {
          document.getElementById("errorSound").play();
        }
      } else if (message.type == "paymentSuccess") {
        this.showPaymentInProgress = false;
        this.showPaymentSuccess = true;
        if (this.isOwner && this.user) {
          this.saveToRoomHistory(message.message.price);
        }
        if (document.getElementById("successSound")) {
          document.getElementById("successSound").play();
        }
      } else if (message.type == "toggleScreenShareEvent") {
        console.log(
          "Message toggleScreenShare onMessageRecieved",
          message.message
        );
        this.desktopSharingActive = message.message;
        this.participantSharing = message.participant;
      } else if (message.type == "checkModerator") {
        console.log("isOwner", this.isOwner);
        console.log("isRoomOwner", this.isRoomOwner);
        console.log("isFingerprintOwner", this.isFingerprintOwner);
        console.log("isHereFirst", this.isHereFirst);
        if (!this.isOwner && !this.isHereFirst) {
          this.connection.disconnect();
          console.log("Too many non-owner in room, leaving");
          this.showRoomOccupied = true;
        }
      } else if (message.type === "nonModeratorHangsUp") {
        if (this.isOwner && this.room.getParticipants().length == 1) {
          this.showUserLeftModal = true;
        }
      } else if (message.type === "requestModeratorRole") {
        if (!this.isOwner || this.$route.name === "call_et_guest") {
          this.room.giveMod(message.userId);
          this.room.takeMod();
        }
      } else if (message.type === "grantIsHereFirst") {
        this.isHereFirst = true;
      }
    },
    onConferenceJoined() {
      console.log("conference joined");
      console.log(">>>>> USer", this.user);
      if (this.user) {
        this.room.setDisplayName(this.user.displayName || "");
      } else if (sessionStorage.getItem("displayName")) {
        this.room.setDisplayName(sessionStorage.getItem("displayName"));
      } else {
        this.room.setDisplayName("");
      }
      if (this.localVideoTrack) {
        this.loadingVideoFeed = false;
        console.log("add video to conference");
        this.room.addTrack(this.localVideoTrack);
      }
      if (this.localAudioTrack) {
        this.loadingAudioFeed = false;
        console.log("add audio to conference");
        this.room.addTrack(this.localAudioTrack);
      }
      for (const participant of this.room.getParticipants()) {
        console.log("sending mesage to " + participant.getId());
        this.sendMessage(participant.getId(), {
          type: "dummy",
          message: "value"
        });
      }
      console.log(">>>>> P LENGTH", this.room.getParticipants().length);
      if (this.room.getParticipants().length == 1) {
        this.isOtherUserAvailable = true;
      }
      if (this.room.getParticipants().length === 0) {
        this.isHereFirst = true;
        console.log(">>>>>>> ", this.isHereFirst);
      } else if (this.room.getParticipants().length === 2) {
        this.connection.disconnect();
        console.log("Too many participants, leaving");
        this.showRoomOccupied = true;
      }
      if (!this.$route.query.f && !this.isRoomOwner) {
        // Only room owner is allowed to use link without fingerprint
        if (!this.isEtRoom) {
          if (!this.isRoomOwner) {
            this.showAlertNotOwner = true;
            setTimeout(() => {
              this.$router.replace("/");
            }, 2500);
          }
        }
      }
      if (
        this.isOwner &&
        !this.isModerator &&
        this.room.getParticipants().length === 1
      ) {
        // Request moderator role from other user
        for (const participant of this.room.getParticipants()) {
          this.sendMessage(participant.getId(), {
            type: "requestModeratorRole",
            userId: this.room.myUserId()
          });
        }
      }
      /*
      console.log("this.room.myUserId", this.room.myUserId())
      if (this.$route.query.f) {
        if (this.isFingerprintOwner) {
          console.log("setting moderator role");
          console.log("this.room ", this.room);
          this.room.giveMod(this.room.myUserId());
          setTimeout(() => {
            if (!this.isModerator) {
              console.log("retry setting moderator role");
              this.room.giveMod(this.room.myUserId());
            }
          }, 2500);
        } else {
          console.log("unsetting moderator role");
          this.room.takeMod();
          setTimeout(() => {
            if (this.isModerator) {
              console.log("retry unsetting moderator role");
              this.room.takeMod();
            }
          }, 2500);
        }
      } else {
        // Only room owner is allowed to use link without fingerprint
        if (!this.isRoomOwner) {
          this.showAlertNotOwner = true;
          setTimeout(() => {
            this.$router.replace("/");
          }, 2500);
        }
      }
      */
      // setTimeout(() => {
      console.log("Im here !! ", this.isOwner);
      if (!this.isEtRoom) {
        if (this.room.getParticipants().length == 0 && !this.isOwner) {
          this.notifyRoomOwner(true);
          this.showOwnerNotified = true;
        }
      }

      // }, 3000)
    },
    onUserJoined(participantId) {
      console.log("user joined " + participantId);
      this.room.selectParticipants([participantId]); // this selects the high quality track offering of this participant(s)
      // if (this.room.isModerator()) this.room.lock(Math.random().toString());
      this.showShareLink = false;
      if (this.isOwner && this.user) {
        this.isOtherUserAvailable = true;
        this.saveToRoomHistory();
      }
      if (this.room.getParticipants().length === 1) {
        if (this.isOwner) {
          this.sendMessage(participantId, {
            type: "grantIsHereFirst"
          });
        } else if (this.isHereFirst) {
          // Prevent situituation where both partcipants are not moderator
          if (!this.isEtRoom) {
            this.sendMessage(participantId, {
              type: "checkModerator"
            });
          }
        }
      }
    },
    onUserLeft() {
      console.log("user left");
      if (this.isOwner) {
        // this.room.unlock();
        console.log(
          "this.room.getParticipants().length",
          this.room.getParticipants().length
        );
        if (this.room.getParticipants().length == 0) {
          this.showShareLink = true;
          this.isOtherUserAvailable = false;
        }
        this.participantDisplayName = "";
        this.localVideoMini = false;
      }
    },
    async onKicked() {
      console.log("kicked");
      if (this.localVideoTrack) await this.disableVideo();
      if (this.localAudioTrack) await this.disableAudio();
      return this.toggleHangUp({ kicked: true });
    },
    onRemoteTrackRemove(track) {
      if (track.isLocal()) return;
      console.log(
        "remote " +
          (track.isP2P ? "p2p " : "") +
          (track.videoType == "desktop" ? "desktop " : "") +
          track.getType() +
          " track removed"
      );
      // this.participantDisplayName = "";
      for (const container of track.containers) {
        track.detach(container);
      }
      if (track.getType() == "video") {
        // this.localVideoMini = false;
        this.remoteHasVideo = false;
      }
    },
    onRemoteTrackAdd(track) {
      if (track.isLocal()) return;
      console.log(
        "remote " +
          (track.isP2P ? "p2p " : "") +
          (track.videoType == "desktop" ? "desktop " : "") +
          track.getType() +
          " track added"
      );
      if (track.getType() == "video") {
        let participantId = track.getParticipantId();
        if (participantId) {
          this.participantDisplayName = this.room.getParticipantById(
            participantId
          )._displayName;
        }
        this.localVideoMini = true;
        track.attach(this.$refs.remoteVideo);
        this.remoteHasVideo = true;
      } else if (track.getType() == "audio") {
        track.attach(this.$refs.remoteAudio);
      }
    },
    onUserRoleChanged(participantId, role) {
      console.log("role of " + participantId + " is now: " + role);
      if (role === "moderator") {
        this.isModerator = true;
      } else {
        this.isModerator = false;
      }
    },
    async toggleAudio() {
      if (this.localAudioTrack) {
        for (const container of this.localAudioTrack.containers) {
          await this.localAudioTrack.detach(container);
        }
        if (this.localAudioTrack.conference && this.room) {
          await this.room.removeTrack(this.localAudioTrack);
        }
        await this.localAudioTrack.dispose();
        this.localAudioTrack = null;
      } else {
        this.createAudioTrack();
      }
    },
    async switchToMobileAudio(type) {
      console.log("switch audio to " + type);
      await this.disableAudio();
      //fitler devices by type
      this.createAudioTrack();
    },
    async switchToFront() {
      await this.disableVideo();
      this.createVideoTrack({ facingMode: { exact: "user" } });
    },
    async switchToBack() {
      await this.disableVideo();
      this.createVideoTrack({ facingMode: { exact: "environment" } });
    },
    async disableAudio() {
      if (this.localAudioTrack) {
        await this.toggleAudio();
      }
    },
    async disableVideo() {
      if (this.localVideoTrack) {
        await this.toggleVideo();
      }
    },
    async toggleVideo() {
      if (this.localVideoTrack) {
        for (const container of this.localVideoTrack.containers) {
          await this.localVideoTrack.detach(container);
        }
        if (this.localVideoTrack.conference && this.room) {
          await this.room.removeTrack(this.localVideoTrack);
        }
        await this.localVideoTrack.dispose();
        this.localVideoTrack = null;
        this.desktopSharingActive = false;
      } else {
        this.createVideoTrack({});
      }
    },
    createVideoTrack(options) {
      this.loadingVideoFeed = true;
      console.log("create video track for " + this.videoDeviceSelected);
      if (this.videoDeviceSelected && !options.facingMode)
        options["cameraDeviceId"] = this.videoDeviceSelected;
      JitsiMeetJS.createLocalTracks({
        devices: ["video"],
        ...options
      })
        .then(tracks => {
          for (var track of tracks) {
            if (track.getType() == "video") {
              localStorage.setItem("videoDeviceId", track.deviceId);
              track.attach(this.$refs.localVideo);
              if (this.room) {
                this.loadingVideoFeed = false;
                console.log("add video to conference");
                this.room.addTrack(track);
              }
              this.localVideoTrack = track;
            }
          }
        })
        .catch(error => {
          this.loadingVideoFeed = false;
          throw error;
        });
    },
    createDesktopTrack() {
      return JitsiMeetJS.createLocalTracks({
        devices: ["desktop"]
      })
        .then(async tracks => {
          for (var track of tracks) {
            if (track.getType() == "video") {
              if (this.localVideoTrack) {
                await this.toggleVideo();
                this.returnToCamera = true;
              }
              track.on(
                JitsiMeetJS.events.track.LOCAL_TRACK_STOPPED,
                this.toggleScreenShare
              );
              track.attach(this.$refs.localVideo);
              if (this.room) {
                console.log("add desktop to conference");
                this.room.addTrack(track);
              }
              this.localVideoTrack = track;
              this.desktopSharingActive = true;
              this.participantSharing = this.room.myUserId();
              for (const participant of this.room.getParticipants()) {
                this.sendMessage(participant.getId(), {
                  type: "toggleScreenShareEvent",
                  message: true,
                  participant: this.room.myUserId()
                });
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    createAudioTrack() {
      this.loadingAudioFeed = true;
      console.log("create audio track for " + this.audioDeviceSelected);
      JitsiMeetJS.createLocalTracks({
        devices: ["audio"],
        micDeviceId: this.audioDeviceSelected
      })
        .then(tracks => {
          for (var track of tracks) {
            if (track.getType() == "audio") {
              track.attach(this.$refs.localAudio);
              this.$refs.localAudio.muted = true;
              if (this.room) {
                this.loadingAudioFeed = false;
                console.log("add audio to conference");
                this.room.addTrack(track);
              }
              this.localAudioTrack = track;
            }
          }
        })
        .catch(error => {
          this.loadingAudioFeed = false;
          throw error;
        });
    },
    onParticipantConnStatusChanged(participantId, status) {
      if (!this.room) return;
      const participant = this.room.getParticipantById(participantId);
      if (!participant) return;
      const isP2P = participant
        .getTracks()
        .reduce((acc, { isP2P }) => acc || isP2P, false);
      const isP2PActive = this.room.isP2PActive();
      console.log(
        "participant connection status changed",
        participantId,
        status,
        isP2P,
        isP2PActive
      );
      if (!this.interruptedTimeout && status === "interrupted") {
        if (isP2P) {
          // sometimes p2p connections fail :(
          this.interruptedTimeout = setTimeout(() => {
            if (this.room && this.room.isJoined() && this.room.isP2PActive()) {
              console.log("stopping p2p session");
              this.room.stopP2PSession();
            }
          }, 300);
        }
      }
      // sometimes connections only fail for some moment
      if (
        this.interruptedTimeout &&
        // for p2p we only consider active/restoring as successful
        ((isP2P && (status === "active" || status === "restoring")) ||
          // for jvb we consider not interrupted for safety against wrongful kick
          (!isP2P && status !== "interrupted"))
      ) {
        clearTimeout(this.interruptedTimeout);
        this.interruptedTimeout = undefined;
      }
    },
    async toggleScreenShare() {
      if (this.desktopSharingActive == true) {
        await this.toggleVideo();
        if (this.returnToCamera == true) {
          this.returnToCamera = false;
          await this.toggleVideo();
        }
        this.participantSharing = this.room.myUserId();
        for (const participant of this.room.getParticipants()) {
          this.sendMessage(participant.getId(), {
            type: "toggleScreenShareEvent",
            message: false,
            participant: false
          });
        }
      } else {
        return this.createDesktopTrack();
      }
    },
    toggleFullScreen() {
      if (!this.IsFullScreen()) {
        // const elem = event.target;
        const elem = document.body;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen();
        }
        this.fullScreen = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen().catch(() => {});
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.fullScreen = false;
      }
    },
    IsFullScreen() {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      );
    },
    async toggleHangUp(options = {}) {
      if (this.interruptedTimeout) {
        clearTimeout(this.interruptedTimeout);
        this.interruptedTimeout = undefined;
      }
      console.log("toggleHangUp", options);
      const isModerator =
        !options.kicked &&
        !options.destroyed &&
        this.room &&
        this.room.isModerator();

      if (this.room) {
        if (isModerator) {
          const participants = this.room.getParticipants();
          if (participants.length == 1) {
            this.room.kickParticipant(participants[0].getId());
          }
        }
        if (!isModerator) {
          for (const participant of this.room.getParticipants()) {
            this.sendMessage(participant.getId(), {
              type: "nonModeratorHangsUp",
              message: true
            });
          }
        }
        if (this.localVideoTrack) {
          if (this.localVideoTrack.conference && this.room) {
            await this.room.removeTrack(this.localVideoTrack);
          }
        }
        if (this.localAudioTrack) {
          if (this.localAudioTrack.conference && this.room) {
            await this.room.removeTrack(this.localAudioTrack);
          }
        }
        if (this.room && this.room.isJoined()) {
          await this.room.leave().catch(err => console.warn("leave room", err));
          this.room = null;
        }
      }
      if (this.localVideoTrack) {
        await this.disableVideo();
      }
      if (this.localAudioTrack) {
        await this.disableAudio();
      }
      if (this.connection) {
        if (
          this.connection.xmpp &&
          this.connection.xmpp.connection &&
          !this.connection.xmpp.disconnectInProgress
        ) {
          await this.connection
            .disconnect()
            .catch(err => console.warn("drop connection", err));
        }
        this.connection = null;
      }
      // if (!isModerator) {
      //   if (this.$router.currentRoute.path !== "/") {
      //     this.$router.replace("/");
      //   }
      // }
      if (!options.kicked) {
        if (this.$router.currentRoute.path !== "/") {
          setTimeout(() => {
            this.$router.replace("/");
          }, 2500);

          // this.$router.replace("/");
        } else {
          EventBus.$emit("showMainPage");
        }
      } else {
        this.showModeratorHangsUp = true;
      }
      // if (isModerator) {
      //   this.$router.replace("/");
      // } else if (!options.destroyed) {
      //   this.showCallEnded = true;
      // }
    },
    updateDevices(devices) {
      var videoDevices = {};
      var audioDevices = {};
      this.mobileAudioBluetooth = false;
      console.log(devices);
      for (const device of devices) {
        if (device.kind == "videoinput") {
          videoDevices[device.deviceId] = device;
        } else if (device.kind == "audioinput") {
          audioDevices[device.deviceId] = device;
          if (device.label.includes("ooth")) {
            this.mobileAudioBluetooth = true;
            console.log("bluetooth found");
          }
        }
      }
      this.videoDevices = videoDevices;
      this.audioDevices = audioDevices;
    },
    closeModalPaymentInProgress() {
      this.showPaymentInProgress = false;
    },
    closeModalRequestPayment() {
      this.showRequestPaymentModal = false;
    },
    closeModalRequestedPayment() {
      this.cancelFunction();
    },
    closePaymentSuccessModal() {
      this.showPaymentSuccess = false;
    },
    closePaymentCanceledModal() {
      this.showPaymentCanceled = false;
    },
    closeStripeConnect() {
      this.showStripeConnect = false;
    },
    setProgressBar(option) {
      this.loadingOther = option;
    },
    closeModalRegisterForPayments() {
      this.showRegisterForPayments = false;
    },
    async showRquestPayment() {
      if (this.isMobile && !this.user) {
        this.showRegisterForPayments = true;
      }

      if (!this.user) return;
      this.loadingOther = true;
      fetch(
        `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/fetchStripeConnectAccount`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            isRaMicro: this.isRaMicro
          })
        }
      )
        .then(response => response.json())
        .then(response => {
          if (response.success && response.account.charges_enabled) {
            this.loadingOther = false;
            this.showRequestPaymentModal = true;
          } else {
            this.loadingOther = false;
            this.showStripeConnect = true;
          }
        })
        .catch(error => {
          this.loadingOther = false;
          console.log(error);
        });
    },
    showPaymentSettings() {
      this.showModalSettings = true;
    },
    closePaymentSettings() {
      this.showModalSettings = false;
    },
    async switchAudio(deviceId) {
      console.log("Switch to microphone with group ID", deviceId);
      this.audioDeviceSelected = deviceId;
      localStorage.setItem("audioDeviceId", deviceId);
      if (this.localAudioTrack) {
        await this.toggleAudio();
      }
      await this.toggleAudio();
    },
    async switchVideo(deviceId) {
      console.log("Switch to cam with group ID", deviceId);
      this.videoDeviceSelected = deviceId;
      if (this.localVideoTrack) {
        await this.toggleVideo();
      }
      await this.toggleVideo();
    },
    showButtons() {
      if (!this.showIcons) {
        this.showIcons = true;
        //this.setIconTimeout(); //#344 disable icon hidding
      }
    },
    clearTimeOutFunction() {
      window.clearTimeout(this.hideIconsAfterTimeout);
    },
    setIconTimeout() {
      if (this.hideIconsAfterTimeout) {
        window.clearTimeout.bind(this, this.hideIconsAfterTimeout);
      }
      this.hideIconsAfterTimeout = window.setTimeout(
        function() {
          this.showIcons = false;
          this.collapseAllMenu();
        }.bind(this),
        5000
      );
    },
    copyLinkClick() {
      this.copyLink(this.getRoomLink + this.fingerPrintString());
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 2000);
    },
    collapseMenu(menuID) {
      document.getElementById(menuID).checked = false;
    },
    collapseAllMenu() {
      document.getElementsByClassName("menu-open").forEach(x => {
        x.checked = false;
      });
    },
    async saveToRoomHistory(paid_value = false) {
      const objToSave = {
        key: this.callUUID,
        roomName: this.roomName,
        date: Date.now(),
        time: "",
        email: this.user.email,
        name:
          this.participantDisplayName ||
          this.user.displayName ||
          this.user.email,
        mwst: "",
        paid_value: paid_value || "",
        tel: ""
      };
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveRoomHistory`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify(objToSave)
      })
        .then(response => response.json())
        .then(response => {
          console.log("Response save to history", response);
        })
        .catch(error => {
          console.log("Error saving to history", error);
        });
    },
    fingerPrintString() {
      if (this.isEtRoom) {
        return "";
      } else {
        let fingerPrint = new FingerprintJS().get();
        let string = "?f=" + fingerPrint + "&ts=" + Date.now();
        if (this.token && !this.isRoomOwner) {
          string += "&token=" + this.token;
        }
        // only required for random unowned room
        if (this.user && this.user.email) {
          string += "&e=" + this.user.email;
        }
        return string;
      }
    },
    async notifyRoomOwner(isEnteringCall) {
      let roomStatus = await fetchRoomStatus(
        this.$route.params.roomid,
        this.$route.params.subRoom
      );
      let room = this.$route.params.roomid;
      if (this.displayName) {
        sessionStorage.setItem("displayName", this.displayName);
      }
      if (this.$route.params.subRoom) {
        room = room + "/" + this.$route.params.subRoom;
      }
      if (this.$route.query.token || roomStatus.online) {
        if (!this.user || (this.user && this.user.uid !== roomStatus.owner)) {
          let device;
          if (this.$route.query.token) {
            // extended link from unregistered or starter users
            device = this.$route.query.token;
          } else {
            // shortened link from the manage room page
            device = await getPushNotificationDevice(roomStatus.owner);
          }
          //TODO: translation
          let roomLink = "/r/";
          let bodyText =
            "User " +
            this.getLocalDisplayName +
            " is waiting in " +
            this.baseUrl + //process.env.VUE_APP_BASE_URL +
            roomLink +
            room +
            ". Click to Join.";

          if (isEnteringCall) {
            roomLink = "/c/";
            bodyText =
              "User " +
              this.getLocalDisplayName +
              " is waiting in " +
              this.baseUrl + //process.env.VUE_APP_BASE_URL +
              roomLink +
              room +
              ". Click to Join.";
          }

          if (this.$route.query.f && this.$route.query.ts) {
            room += "?f=" + this.$route.query.f + "&ts=" + this.$route.query.ts;
          }

          const notification = {
            title: "easymeet.me",
            body: bodyText,
            icon: "img/icons/favicon-32x32.png",
            tag: window.location.origin + roomLink + room,
            requireInteraction: true,
            roomid: this.$route.params.roomid,
            subroom: this.$route.params.subRoom
          };
          fetch("https://fcm.googleapis.com/fcm/send", {
            method: "POST",
            headers: {
              Authorization:
                "key=" + process.env.VUE_APP_FIREBASE_MESSAGESENDERID,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              data: notification,
              to: device
            })
          })
            .then(function(response) {
              console.log(response);
            })
            .catch(function(error) {
              console.error(error);
            });

          if (roomStatus.email || this.$route.query.e) {
            let roomUrl = this.baseUrl + roomLink + room;

            fetch(
              `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/sendemailbasicauth`,
              {
                method: "POST",
                headers: {
                  Authorization:
                    "Basic " +
                    Buffer.from("easymeet:ZGbHo3Dd", "utf-8").toString(
                      "base64"
                    ),
                  "Content-Type": "application/json;charset=UTF-8"
                },
                body: JSON.stringify({
                  email: roomStatus.email || this.$route.query.e,
                  subject:
                    "easymeet.me: " +
                    this.getLocalDisplayName +
                    " is waiting in room",
                  message: `${this.getLocalDisplayName} is waiting in room: <a href="${roomUrl}"> ${roomUrl} </a>`
                })
              }
            ).catch(error => {
              console.log(error);
            });
          }
        }
      }
    }
  },
  computed: {
    // getTotalParticipants(){
    //   console.log('this.room.getParticipants().length', this.room && this.room.getParticipants().length)
    //   return this.room && this.room.getParticipants().length
    // },
    getLocalDisplayName() {
      return sessionStorage.getItem("displayName");
    },
    user() {
      return this.$store.state.user.user;
    },
    getRoomLink() {
      let link = window.location.href.split("?")[0];
      return link.replace("/c/", "/r/");
    },
    userHasPayments() {
      return this.hasPayments;
    },
    isOwner() {
      return (
        this.isRoomOwner ||
        this.isFingerprintOwner ||
        this.$route.name === "call_et_moderator"
      );
    },
    isEtRoom() {
      return (
        this.$route.name === "call_et" ||
        this.$route.name === "call_et_moderator" ||
        this.$route.name === "call_et_guest"
      );
    }
  },
  beforeDestroy: async function() {
    console.log("beforeDestroy()");
    window.removeEventListener("resize", this.windowResize);
    JitsiMeetJS.mediaDevices.removeEventListener(
      JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
      this.updateDevices
    );
    if (this.localVideoTrack) await this.disableVideo();
    if (this.localAudioTrack) await this.disableAudio();
    if (this.isRaMicro) {
      document.removeEventListener("keydown", function(event) {
        if (event.key === "Escape") {
          this.toggleHangUp();
        }
      });
    }
    return this.toggleHangUp({ destroyed: true });
  },
  destroyed() {
    console.log("destroyed()");
    let elHtml = document.getElementsByTagName("html")[0];
    elHtml.style.overflow = "";
  }
};
</script>
<style scoped lang="scss">
.noVideoParent {
  height: 100%;
  width: 100%;
  display: table;
  background: #000000c4;
  .noVideo {
    height: 100%;
    width: 100%;
    display: table-cell;
    font-size: 22px;
    color: white;
    vertical-align: middle;
    & img {
      height: 60px;
      width: 60px;
      border-radius: 50%;
    }
  }
}
.remoteVideoParent {
  &.active {
    width: 100%;
    height: 100%;
  }
}
.parentLocalVideo {
  width: 100%;
  height: 100%;
  &.mini {
    transition: all 0.7s linear;
    /*border: 1px solid gray;*/
    bottom: 20px;
    right: 20px;
    max-height: 165px; /*17%;*/
    max-width: 370px; /*17%;*/
    position: absolute;
    @media only screen and (max-width: 768px) {
      max-width: 30%;
      max-height: 25%;
      height: 25%;
      width: 30%;
    }
  }
}
.panel_iframe {
  height: 30px;
  position: absolute;
  border-top: 22px solid rgb(0 0 0 / 60%);
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
  background-color: transparent;
  padding: 10px;
  width: 210px;
  left: calc((100% - 210px) / 2);
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  color: white;
  z-index: 9;
}

.li_icons_content {
  display: inline-block;
  cursor: pointer;
  padding: 2px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.ul_icons_content {
  list-style: none;
  padding-right: 0px;
  padding-left: 0px;
  position: relative;
  top: -35px;
}
.hidden {
  display: none;
}
.watermark {
  opacity: 0.6;
  position: absolute;
  z-index: 1;
  width: auto;
  border-radius: 50%;
  left: 6vw;
  top: 25px;
}
.regularBar {
  display: inline;
}
.spanWaiting {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  left: 0;
  z-index: -1;
  animation: blink 2s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
.logoCall {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: -3px;
}
.pullRight {
  float: right;
}
.call {
  position: relative;
}
.pRelative {
  position: relative;

  .buttonMore {
    position: absolute;
    bottom: -3px;
    height: 15px;
    width: 15px;
    background-color: #666;
    right: -3px;
    opacity: 0.6;

    &:hover {
      background: #407cf7;
    }
  }
}

.v-list {
  box-shadow: rgb(13 20 36 / 85%) 0px 4px 8px -2px,
    rgb(13 20 36 / 81%) 0px 0px 1px;

  .v-list-item__title {
    color: white;
  }

  .v-list-item:not(.font-weight-medium) {
    &:hover {
      background: #407cf7;
    }
  }
}

.cursorPointer {
  cursor: pointer;
}

.alertSuccess {
  background-color: black;
  color: white;
  max-width: 170px;
  top: 15px;
  left: calc(100% - 170px);
  padding: 5px;
  font-size: 14px;
}

.infoAlert {
  background-color: black;
  color: white;
  max-width: 250px;
  width: auto;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  position: fixed;
  animation: blink 2s linear infinite;
}

#videos {
  font-size: 0;
  height: 100%;
  // pointer-events: none;
  position: absolute;
  -webkit-transition: all 1s;
  transition: all 1s;
  width: 100%;
  left: 0;
  background: black;
  top: 0;
}

.icons {
  left: 6vw;
  position: absolute;
  cursor: pointer;
  z-index: 2;

  &.active {
    .buttonsCall {
      transform: translateX(0);
    }
  }

  .buttonsCall {
    border-radius: 50%;
    background-color: #666;
    box-shadow: 2px 2px 24px #636363;
    opacity: 0.6;
    display: block;
    margin: 0 0 3vh 0;
    transform: translateX(calc(-6vw - 96px));
    transition: all 0.1s;
    transition-timing-function: ease-in-out;

    .rotatedIcon {
      transform: rotate(226deg);
    }

    &:hover {
      box-shadow: 4px 4px 48px rgb(14, 13, 13);
      background: #407cf7;
    }
  }
}

.divRoomLink {
  background-color: black;
  bottom: 0px !important;
  color: white;
  display: none;
  font-size: 0.9em;
  font-weight: 300;
  line-height: 2em;
  max-height: 80%;
  opacity: 0;
  overflow-y: auto;
  padding: 10px;
  position: fixed !important;
  transition: opacity 1s;
  width: calc(100% - 20px);
  text-align: left;
  font-size: 16px;
  width: 100%;

  a {
    white-space: nowrap;
  }

  &.active {
    display: block;
    opacity: 0.8;
  }
}

.localVideo {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  &.noContentFit {
    object-fit: contain;
  }
  // &.mini {
  //   transition: all 0.7s linear;
  //   /*border: 1px solid gray;*/
  //   bottom: 20px;
  //   right: 20px;
  //   max-height: 165px; /*17%;*/
  //   max-width: 370px; /*17%;*/
  //   position: absolute;
  //   @media only screen and (max-width: 768px) {
  //     max-width: 30%;
  //     max-height: 25%;
  //     height: 25%;
  //     width: 30%;
  //   }
  // }
}

.remoteVideo {
  display: block;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  /* no letterboxing */
  opacity: 0;
  position: absolute;
  transition: opacity 1s;
  width: 100%;

  &.active {
    opacity: 1;
    /*z-index: -1;*/
  }
  &.noContentFit {
    object-fit: contain;
  }
}

#userInfoFloatingBox {
  color: #80808080;
  font-size: 25px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

#blurHoverBox {
  display: block;
  position: absolute;
  z-index: 1;
  width: calc(6vw + 36px + 10px);
  height: calc(3vh * 6 + 36px * 6 + 65px);
  transform: translateY(calc(100vh - 3vh * 6 - 36px * 6 - 65px));
}

.blurVideo:hover ~ #videos {
  filter: contrast(50%) brightness(50%);
}

// FLOATING
//vars
$fg: #666;
$pi: 3.14;

//config
$menu-items: 4;
$open-distance: 105px;
$opening-angle: $pi * 2;

%ball {
  background: $fg;
  border-radius: 100%;
  width: 36px;
  height: 36px;
  position: absolute;
  color: white;
  text-align: center;
  line-height: 36px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
}

.menu-open {
  display: none;
}

.menu-item {
  @extend %ball;
}

.menu {
  $width: auto;
  $height: 36px;
  display: block;
  margin: 0 0 3vh 0;
  left: 50%;
  width: $width;
  height: $height;
  box-sizing: border-box;
  font-size: 20px;
  text-align: left;
  opacity: 0.6;
}

.menu-item {
  &:hover {
    background: #407cf7;
    color: white;
  }

  @for $i from 1 through $menu-items {
    &:nth-child(#{$i + 2}) {
      transition-duration: 180ms;
    }
  }
}

.menu-open-button {
  @extend %ball;
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
}

.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

  @for $i from 1 through $menu-items {
    &:nth-child(#{$i + 2}) {
      transition-duration: 90ms+ (100ms * $i);
      transform: translate3d(50px * $i, 0, 0);
    }
  }
}

@media only screen and (max-width: 670px) {
  .panelLocal {
    width: 100%;
    left: 0px;
  }
}
.redBlinking {
  color: red;
  animation: blink 2s linear infinite;
}
</style>
<style lang="scss">
.buttonsCall {
  .v-ripple__container {
    display: none !important;
  }
}
</style>
