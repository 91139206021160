<template>
  <v-card class="cardProfile" flat>
    <v-row>
      <v-col cols="12">
        <v-avatar
          size="150px"
          class="mb-4 links"
          rounded
          :color="
            isRaMicro && !user.photoURL && !user.customPhotoURL ? 'primary' : ''
          "
        >
          <img
            contain
            v-if="user.customPhotoURL"
            :src="user.customPhotoURL"
            @click="showAddPhoto"
          />
          <img
            contain
            v-else-if="user.photoURL"
            :src="user.photoURL"
            @click="showAddPhoto"
          />
          <span
            @click="showAddPhoto"
            class="white--text"
            style="font-size: 50px;"
            v-else-if="!user.photoURL && !user.customPhotoURL && isRaMicro"
            >{{ getAvatar }}</span
          >
        </v-avatar>
        <v-col cols="12" class="mt-0 mb-1">
          <v-btn-toggle v-model="toggle_exclusive" class="primary">
            <v-btn depressed color="primary" @click="startNow" large>
              <font-awesome-icon
                class="mr-3"
                :icon="['fas', 'phone-alt']"
                :style="{ fontSize: 15 }"
              />
              {{ $t("profile.homeProfile.videoStart") }}
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  depressed
                  class="buttonEuroMainScreen"
                  @click="showLinkModal"
                  :loading="loading"
                  large
                >
                  {{ $t("profile.homeProfile.invite") }}
                </v-btn>
              </template>
              <span>
                {{ getTooltipButton }}
              </span>
            </v-tooltip>
          </v-btn-toggle>
        </v-col>
        <p class="mb-0">{{ user.email }}</p>
      </v-col>
      <v-col cols="12">
        <h1 class="pb-2">
          {{ $t("profile.homeProfile.welcome") }},
          <span>{{ user.displayName || user.email }}</span>
        </h1>
        <p v-if="!isRaMicro">
          {{ $t("profile.homeProfile.title-1") }}
          <strong>easy</strong>meet.me {{ $t("profile.homeProfile.title-1-2") }}
        </p>
      </v-col>
    </v-row>
    <v-row class="rowPills align-stretch pt-3">
      <v-col cols="12" md="6" lg="6">
        <div class="bordered pa-5" @click="showSection('info')">
          <div class="text-left">
            <p class="title titlePill">
              {{ $t("profile.personalInfo.personalInfo") }}
            </p>
            <p class="body-1 pr-md-12" v-if="!isRaMicro">
              {{ $t("profile.homeProfile.personalInfoText") }}
              <strong>easy</strong>meet.me
              {{ $t("profile.homeProfile.personalInfoText-1") }}
            </p>
            <font-awesome-icon
              class="iconCard"
              :icon="['fal', 'address-card']"
              :style="{ fontSize: 24 }"
            />
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="6" v-if="!isRaMicro">
        <div class="bordered pa-5" @click="showSection('subscriptions')">
          <div class="text-left">
            <p class="title titlePill">
              {{ $t("profile.homeProfile.subscriptions") }}
            </p>
            <p class="body-1 pr-md-12" v-if="!isRaMicro">
              {{ $t("profile.homeProfile.subscriptionsText") }}
            </p>

            <font-awesome-icon
              class="iconCard"
              :icon="['fal', 'money-check-edit-alt']"
              :style="{ fontSize: 24 }"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <div class="bordered pa-5" @click="showSection('rooms')">
          <div class="text-left">
            <p class="title titlePill">
              {{ $t("profile.homeProfile.rooms") }}
            </p>
            <p class="body-1 pr-md-12" v-if="!isRaMicro">
              {{ $t("profile.homeProfile.roomAndMeetingsText") }}
            </p>

            <font-awesome-icon
              class="iconCard"
              :icon="['fal', 'sitemap']"
              :style="{ fontSize: 24 }"
            />
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="6">
        <div class="bordered pa-5" @click="showSection('payments')">
          <div class="text-left">
            <p class="title titlePill">
              {{ $t("profile.homeProfile.payment") }}
            </p>
            <p class="body-1 pr-md-12" v-if="!isRaMicro">
              {{ $t("profile.homeProfile.clientPaymentsText") }}
            </p>

            <font-awesome-icon
              class="iconCard"
              :icon="['fal', 'hands-usd']"
              :style="{ fontSize: 24 }"
            />
          </div>
        </div>
        <AddPhotoModal
          :showModalAddPhoto="showModalAddPhoto"
          :closeModal="closeAddPhoto"
        />
      </v-col>

      <v-col
        v-if="(!isStarter && !isRaMicro) || isRaMicro"
        cols="12"
        md="6"
        lg="6"
      >
        <div class="bordered pa-5" @click="showSection('addressbook')">
          <div class="text-left">
            <p class="title titlePill">
              {{ $t("profile.addressBook.addressbook") }}
            </p>
            <p
              class="body-1 pr-md-12"
              v-if="!isRaMicro"
              v-html="$t('profile.addressBook.box1')"
            ></p>

            <font-awesome-icon
              class="iconCard"
              :icon="['fal', 'address-book']"
              :style="{ fontSize: 24 }"
            />
          </div>
        </div>
      </v-col>

      <v-col cols="12" md="6" lg="6">
        <div class="bordered pa-5" @click="showSection('roomHistory')">
          <div class="text-left">
            <p class="title titlePill">
              {{ $t("profile.roomHistory.titel") }}
            </p>
            <!-- <p
              class="body-1 pr-md-12"
              v-html="$t('profile.addressBook.box1')"
            ></p> -->

            <font-awesome-icon
              class="iconCard"
              :icon="['fal', 'history']"
              :style="{ fontSize: 24 }"
            />
          </div>
          <!-- <p class="primary--text mb-0 text-left mt-4">
            {{ $t("profile.addressBook.box2") }}
          </p> -->
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import AddPhotoModal from "./modals/addphoto.vue";
import { getFirebaseToken, getFirebaseClaims } from "@/firebase";
import { randomString, isRaMicro } from "../../utils";
import FingerprintJS from "fingerprintjs";
export default {
  name: "HomeProfile",
  props: ["showSection"],
  components: {
    AddPhotoModal
  },
  data: () => ({
    showModalAddPhoto: false,
    isStarter: false,
    toggle_exclusive: 2,
    isRaMicro: isRaMicro()
  }),
  created() {
    getFirebaseClaims(true)
      .then(claims => {
        if (!claims.hasSub) {
          this.isStarter = true;
        }
      })
      .catch(err => {
        console.warn("getFirebaseClaims Err:", err);
      });
  },
  mounted() {
    this.updateTransactionHistory();
    if (this.$route.params.goToSubscribe) {
      this.showSection("subscriptions");
    }
  },
  methods: {
    showAddPhoto() {
      this.showModalAddPhoto = true;
    },
    closeAddPhoto() {
      this.showModalAddPhoto = false;
    },
    async updateTransactionHistory() {
      if (this.user.ref) {
        fetch(
          `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/updateTransactionHistory`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (await getFirebaseToken()),
              "Content-Type": "application/json;charset=UTF-8"
            }
          }
        );
      }
    },
    startNow() {
      if (!this.isRaMicro) {
        this.initCall();
      } else {
        if (this.user) {
          this.goToStartCallPage();
          // this.initCall();
        } else {
          this.showNeedsLogin = true;
        }
      }
    },
    goToStartCallPage() {
      this.$router.push({ path: `/start` });
    },
    async initCall() {
      const roomId = randomString(9);
      fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/reserveRandomRoom`, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + (await getFirebaseToken()),
          "Content-Type": "application/json;charset=UTF-8"
        },
        body: JSON.stringify({
          room: roomId
        })
      })
        .then(response => response.json())
        .then(response => {
          if (response.success) {
            let fingerPrint = new FingerprintJS().get();
            let ts = Date.now();
            this.$router.push({
              path: `/c/${roomId}?f=${fingerPrint}&ts=${ts}`
            });
            this.showCallComponent = true;
          } else {
            // Try again with a different room
            this.initCall();
          }
        });
    },
    async showLinkModal() {
      if (this.user && !this.hasNotPayments) {
        this.loading = true;
        let subRoom = await this.generateRandomSubroom();
        let room = this.user.mainRoom.name + "/" + subRoom;
        this.loading = false;
        let fingerPrint = new FingerprintJS().get();
        let ts = Date.now();
        this.$router.push({
          path: `/c/${room}?f=${fingerPrint}&ts=${ts}`,
          query: { payLink: "true" }
        });
      }
    },
    async generateRandomSubroom() {
      if (await this.ensureMainRoom()) {
        let savedRooms = [];
        let subRoomName = randomString(9);

        if (this.$store.state.user.user.savedRooms) {
          savedRooms = this.$store.state.user.user.savedRooms.slice();
        }
        savedRooms.push({
          name: subRoomName,
          online: true,
          locked: true,
          isPayLinkRoom: true
        });
        let response = await fetch(
          `${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/saveSubrooms`,
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + (await getFirebaseToken()),
              "Content-Type": "application/json;charset=UTF-8"
            },
            body: JSON.stringify({
              room_name: this.user.mainRoom.name,
              online: this.user.mainRoom.online,
              subroom: savedRooms
            })
          }
        );

        let responseJson = await response.json();
        if (responseJson.success) {
          this.$store.dispatch("SAVE_USER_INFO", {
            user: this.$store.state.user,
            userInfo: {
              savedRooms: savedRooms
            }
          });
          return subRoomName;
        }
      }
    },
    async ensureMainRoom() {
      if (this.user.mainRoom && this.user.mainRoom.name) {
        return true;
      } else {
        // If user does not have a main room, generate a random one
        let mainRoomName = randomString(9);
        fetch(`${process.env.VUE_APP_CLOUD_FUNCTIONS_URL}/claimRoom`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + (await getFirebaseToken()),
            "Content-Type": "application/json;charset=UTF-8"
          },
          body: JSON.stringify({
            room_name: mainRoomName
          })
        })
          .then(response => response.json())
          .then(response => {
            if (response.success) {
              this.$store.dispatch("SAVE_USER_INFO", {
                user: this.$store.state.user,
                userInfo: {
                  mainRoom: { name: mainRoomName, online: true }
                }
              });
              const user = this.user;
              this.$store.dispatch("USER_FETCH", { user });
              return true;
            } else {
              return false;
            }
          })
          .catch(error => {
            console.warn("Error on claimRoom", error);
            return false;
          });
      }
    }
  },
  computed: {
    getAvatar() {
      let avatarN = "";
      let stringToLoop;
      if (this.user.displayName) {
        stringToLoop = this.user.displayName;
      } else {
        stringToLoop = this.user.email;
      }
      for (var i = 0; i < stringToLoop.length; i++) {
        if (avatarN.length < 2 && /^[a-z0-9]+$/i.test(stringToLoop.charAt(i))) {
          avatarN += stringToLoop.charAt(i);
        }
      }
      return avatarN.toUpperCase();
    },
    user() {
      return this.$store.state.user.user;
    }
  }
};
</script>
<style scoped lang="scss">
.links {
  cursor: pointer;
}

.iconCard {
  position: absolute;
  top: 15px;
  right: 15px;
}

.titlePill {
  width: calc(100% - 50px);
}
.rowPills {
  width: 60%;
  max-width: 850px;
  margin: 0 auto;
}

.cardProfile {
  display: table;
  height: 100%;
  width: 100%;
  padding: 15px;
  .contentProfile {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    width: 100%;
  }
}
.bordered {
  position: relative;
  border: 1px solid #ccc;
  min-height: 150px;
  border-radius: 10px;
  height: 100%;
  cursor: pointer;
  &:hover {
    background-color: #efefef;
  }
}
.opacityOnHover {
  opacity: 0 !important;
  &:hover {
    opacity: 0.95 !important;
  }
}
.cardCover {
  color: white;
  align-items: center;
  left: 0;
  bottom: 0;
  justify-content: center;
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 100%;
  transition-duration: 400ms;
}
.blink {
  animation: blink 2s linear infinite;
}
@keyframes blink {
  0% {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
.buttonEuroMainScreen {
  background-color: #42d13f !important; //#4EF04A !important;
  color: white !important;
  opacity: 1 !important;
}
@media screen and (max-width: 1000px) {
  .rowPills {
    width: 100%;
  }
}
@media screen and (max-width: 1470px) and (min-width: 1000.1px) {
  .rowPills {
    width: 80%;
  }
}
</style>
